import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Grid, Typography, Button } from '@mui/material';
import spellcraftingArmor from '../../data/spellcraftingArmor.json';
import spellcraftingWeapons from '../../data/spellcraftingWeapons.json';
import gemData from '../../data/gemData.json';
import calculateSingleItemUtility from '../../utility/calculateUtility';


const Spellcrafting = ({ template, selectedSlot, addGearToSlot, gearBySlot }) => {
    const realm = template.realm;
    const weaponSlots = ['Main Hand', 'Off Hand', 'Two-Hand', 'Ranged'];
    const isWeaponSlot = weaponSlots.includes(selectedSlot);
    const maxImbuePoints = 37.5;
    const initialArmorType = Object.keys(spellcraftingArmor[template.realm])[0] || '';
    const initialArmorPieces = initialArmorType ? Object.keys(spellcraftingArmor[template.realm][initialArmorType][selectedSlot] || {}) : [];
    const initialArmorPiece = initialArmorPieces.length > 0 ? initialArmorPieces[0] : '';

    const createGem = () => {
        const defaultGemType = Object.keys(gemData[realm])[0] || '';
        const defaultGemId = gemData[realm][defaultGemType]?.id?.[0] || '';
        const defaultGemValues = gemData[realm][defaultGemType]?.values;
        const defaultGemValue = defaultGemValues && defaultGemValues.length > 0 ? defaultGemValues[0] : '';
        return {
            type: defaultGemType,
            id: defaultGemId,
            value: defaultGemValue,
            isGem: true
        };
    };

    const initialGem = createGem();
    const nonSpellcraftingSlots = ['Necklace', 'Cloak', 'Jewel', 'Belt', 'Ring', 'Bracer', 'Mythirian'];
    const isNonSpellcraftingSlot = nonSpellcraftingSlots.includes(selectedSlot);

    const [selectedArmorType, setSelectedArmorType] = useState(initialArmorType);
    const [selectedArmorPiece, setSelectedArmorPiece] = useState(initialArmorPiece);
    const [selectedWeaponType, setSelectedWeaponType] = useState('');
    const [selectedWeapon, setSelectedWeapon] = useState('');
    const [selectedShieldSize, setSelectedShieldSize] = useState('');
    const [gems, setGems] = useState([initialGem]);
    const [currentImbuePoints, setCurrentImbuePoints] = useState(0);
    const [isInitialSetupDone, setIsInitialSetupDone] = useState(false);

    const setDefaultValues = () => {
        setSelectedArmorType(initialArmorType);
        setSelectedArmorPiece(initialArmorPieces.length > 0 ? initialArmorPieces[0] : '');

        // Set default values for weapons if a weapon slot is selected
        if (isWeaponSlot && realm in spellcraftingWeapons) {
            const weaponTypes = Object.keys(spellcraftingWeapons[realm]);
            const defaultWeaponType = weaponTypes.length > 0 ? weaponTypes[0] : '';
            setSelectedWeaponType(defaultWeaponType);

            if (defaultWeaponType) {
                const weapons = Object.keys(spellcraftingWeapons[realm][defaultWeaponType]);
                setSelectedWeapon(weapons.length > 0 ? weapons[0] : '');
            } else {
                setSelectedWeapon('');
            }
        } else {
            setSelectedWeaponType('');
            setSelectedWeapon('');
        }

        setSelectedShieldSize('');
        setGems([createGem()]);
    };

    const findArmorTypeAndPiece = (itemName, realm) => {
        for (const armorType in spellcraftingArmor[realm]) {
            for (const slot in spellcraftingArmor[realm][armorType]) {
                if (spellcraftingArmor[realm][armorType][slot].hasOwnProperty(itemName)) {
                    return { armorType, armorPiece: itemName };
                }
            }
        }
        return { armorType: initialArmorType, armorPiece: initialArmorPiece };
    };


    const loadExistingOrSetDefault = () => {
        const existingItem = gearBySlot[selectedSlot];

        if (existingItem && existingItem.tags && existingItem.tags.includes("crafted")) {
            if (isWeaponSlot) {
                setSelectedWeapon(existingItem.name || '');

                const { weaponType, shieldSize } = inferWeaponTypeAndSize(existingItem.name, realm);
                setSelectedWeaponType(weaponType);
                if (weaponType === 'Shield') {
                    setSelectedShieldSize(shieldSize);
                }

                const gemBonuses = existingItem.bonuses.filter(bonus => bonus.isGem)
                    .map(bonus => ({
                        ...bonus,
                        id: bonus.id || findDefaultGemId(bonus.type)
                    }));
                setGems(gemBonuses.length > 0 ? gemBonuses : [createGem()]);
                calculateImbuePoints(gemBonuses.length > 0 ? gemBonuses : [createGem()]);
            } else {
                const { armorType, armorPiece } = findArmorTypeAndPiece(existingItem.name, realm);
                setSelectedArmorType(armorType);
                setSelectedArmorPiece(armorPiece);

                const gemBonuses = existingItem.bonuses.filter(bonus => bonus.isGem)
                    .map(bonus => ({
                        ...bonus,
                        id: bonus.id || findDefaultGemId(bonus.type)
                    }));
                setGems(gemBonuses.length > 0 ? gemBonuses : [createGem()]);
                calculateImbuePoints(gemBonuses.length > 0 ? gemBonuses : [createGem()]);
            }
        } else {
            setDefaultValues();
        }
    };

    const inferWeaponTypeAndSize = (weaponName, realm) => {
        const weaponTypes = spellcraftingWeapons[realm];
        for (const type in weaponTypes) {
            if (type === 'Shield') {
                for (const size in weaponTypes[type]) {
                    if (weaponTypes[type][size].hasOwnProperty(weaponName)) {
                        return { weaponType: type, shieldSize: size }; // Return both type and size for shields
                    }
                }
            } else if (weaponTypes[type].hasOwnProperty(weaponName)) {
                return { weaponType: type }; // Return only type for non-shields
            }
        }
        return { weaponType: '', shieldSize: '' }; // Return empty strings if no match is found
    };




    // Helper function to find the default gem ID for a given gem type
    const findDefaultGemId = (gemType) => {
        return gemData[realm][gemType]?.id?.[0] || '';
    };

    useEffect(() => {
        // Check if the selected slot is a weapon slot and set default weapon type and weapon
        if (isWeaponSlot && realm in spellcraftingWeapons) {
            const weaponTypes = Object.keys(spellcraftingWeapons[realm]);
            const defaultWeaponType = weaponTypes.length > 0 ? weaponTypes[0] : '';
            setSelectedWeaponType(defaultWeaponType);

            if (defaultWeaponType) {
                const weapons = Object.keys(spellcraftingWeapons[realm][defaultWeaponType]);
                setSelectedWeapon(weapons.length > 0 ? weapons[0] : '');
            } else {
                setSelectedWeapon('');
            }
        }

        loadExistingOrSetDefault();
        setIsInitialSetupDone(true);
    }, [selectedSlot, realm]);


    const handleWeaponTypeChange = (event) => {
        const newWeaponType = event.target.value;
        setSelectedWeaponType(newWeaponType);

        // Check if the weapon type is 'Shield' and handle accordingly
        if (newWeaponType === 'Shield') {
            const defaultShieldSize = 'Small'; // or any valid default size
            setSelectedShieldSize(defaultShieldSize);
            updateShieldWeapon(defaultShieldSize);
        } else {
            setSelectedShieldSize('');
            updateWeapon(newWeaponType);
        }
    };

    const handleShieldSizeChange = (event) => {
        const newSize = event.target.value;
        setSelectedShieldSize(newSize);
        updateShieldWeapon(newSize);
    };

    const updateShieldWeapon = (size) => {
        // Safely access shield data
        const shieldData = spellcraftingWeapons[realm]?.Shield?.[size];
        if (shieldData) {
            const shields = Object.keys(shieldData);
            setSelectedWeapon(shields.length > 0 ? shields[0] : '');
        } else {
            setSelectedWeapon('');
        }
    };

    const updateWeapon = (weaponType) => {
        // Safely access weapon data
        const weaponData = spellcraftingWeapons[realm]?.[weaponType];
        if (weaponData) {
            const weapons = Object.keys(weaponData);
            setSelectedWeapon(weapons.length > 0 ? weapons[0] : '');
        } else {
            setSelectedWeapon('');
        }
    };

    const updateArmorPiece = (armorType) => {
        // Update the armor piece based on the selected armor type
        const armorPieces = Object.keys(spellcraftingArmor[realm][armorType][selectedSlot] || {});
        setSelectedArmorPiece(armorPieces.length > 0 ? armorPieces[0] : '');
    };

    const handleArmorTypeChange = (event) => {
        const newType = event.target.value;
        setSelectedArmorType(newType);
        updateArmorPiece(newType); // Update armor piece when armor type changes
    };

    const handleAddGearToSlot = (setActive = true) => {
        if (!isNonSpellcraftingSlot && (!gearBySlot[selectedSlot] || (gearBySlot[selectedSlot].tags && gearBySlot[selectedSlot].tags.includes("crafted")))) {
            const craftedGear = JSON.parse(createCraftedGearJson());
            addGearToSlot(selectedSlot, craftedGear, setActive);
        }
    };

    const handleAddGearToInventory = () => {
        handleAddGearToSlot(false);
    };


    const addGemSlot = () => {
        if (gems.length < 4) {
            setGems([...gems, createGem()]);
        }
    };

    const handleGemChange = (index, field, value) => {
        const newGems = [...gems];
        newGems[index] = { ...newGems[index], [field]: value };

        if (field === 'type') {
            // Check if the new gem type has associated IDs and set the first one as default
            const newType = value;
            const idsAvailable = gemData[realm][newType]?.id;
            if (idsAvailable && idsAvailable.length > 0) {
                newGems[index].id = idsAvailable[0];
            } else {
                newGems[index].id = ''; // Reset ID if no IDs are available for the new type
            }

            // Reset value to the first valid value for the new type
            const validValues = gemData[realm][newType]?.values;
            newGems[index].value = validValues && validValues.length > 0 ? validValues[0] : 0;
        } else if (field === 'id') {
            // Reset value when id changes if the current value is not valid for the new id
            const validValues = gemData[realm][newGems[index].type]?.values;
            if (!validValues.includes(newGems[index].value)) {
                newGems[index].value = validValues[0] || 0;
            }
        }

        setGems(newGems);
        calculateImbuePoints(newGems); // Recalculate imbue points if necessary
    };


    const removeGemSlot = (index) => {
        setGems(gems.filter((_, i) => i !== index));
    };

    const calculateImbuePoints = (gems) => {
        let totalImbuePoints = 0;
        let maxPoints = 0;

        // Calculate the full value for the highest point gem and half value for others
        gems.forEach(gem => {
            if (gem.type && gem.value) {
                const valueIndex = gemData[realm][gem.type].values.indexOf(gem.value);
                if (valueIndex !== -1) {
                    const points = gemData[realm][gem.type].points[valueIndex];
                    maxPoints = Math.max(maxPoints, points);
                    totalImbuePoints += points / 2; // Initially count half value for each gem
                }
            }
        });

        totalImbuePoints = totalImbuePoints - maxPoints / 2 + maxPoints; // Subtract the half value of the highest gem and add its full value
        setCurrentImbuePoints(totalImbuePoints);
    };

    const generateUniqueId = () => {
        return Date.now().toString(36) + Math.random().toString(36).slice(2);
    };

    const createCraftedGearJson = () => {
        let itemBonuses = [];

        if (isWeaponSlot) {
            if (selectedWeaponType && selectedWeapon) {
                const weaponData = spellcraftingWeapons[realm][selectedWeaponType];
                itemBonuses = selectedWeaponType === 'Shield' && selectedShieldSize
                    ? weaponData[selectedShieldSize]?.[selectedWeapon] || []
                    : weaponData?.[selectedWeapon] || [];
            }
        } else if (selectedArmorPiece) {
            const armorData = spellcraftingArmor[realm][selectedArmorType]?.[selectedSlot]?.[selectedArmorPiece] || [];
            itemBonuses = armorData;
        }

        const gemBonuses = gems.map(gem => {
            // Check if the gem type is "All Skills" and change it to "Skills"
            let gemType = gem.type;
            if (gemType === 'All Skill') {
                gemType = 'Skills';
            }

            return {
                type: gemType,
                value: gem.value,
                id: gem.id,
                isGem: true
            };
        });

        const craftedGear = {
            id: generateUniqueId(),
            name: isWeaponSlot ? selectedWeapon : selectedArmorPiece,
            slot: selectedSlot,
            realm: realm,
            bonuses: [...itemBonuses, ...gemBonuses],
            tags: ["crafted"]
        };

        // Calculate utility for the crafted gear
        craftedGear.utility = calculateSingleItemUtility(craftedGear);

        return JSON.stringify(craftedGear, null, 2);
    };

    const getArmorBonusDisplay = (armorName) => {
        const armorBonuses = spellcraftingArmor[realm][selectedArmorType][selectedSlot][armorName];
        return armorBonuses.map(bonus => {
            if (bonus.id && bonus.type === "Toa Overcap") {
                return ` (${bonus.id} Cap: ${bonus.value})`;
            } else if (bonus.id) {
                return ` (${bonus.id}: ${bonus.value})`;
            } else {
                return ` (${bonus.type}: ${bonus.value})`;
            }
        }).join(', ');
    };

    const getWeaponBonusDisplay = (weaponName) => {
        const weaponBonuses = isWeaponSlot
            ? (selectedWeaponType === 'Shield'
                ? spellcraftingWeapons[realm]?.Shield?.[selectedShieldSize]?.[weaponName]
                : spellcraftingWeapons[realm]?.[selectedWeaponType]?.[weaponName])
            : [];
        return weaponBonuses.map(bonus => {
            if (bonus.id && bonus.type === "Toa Overcap") {
                return ` (${bonus.id} Cap: ${bonus.value})`;
            } else if (bonus.id) {
                return ` (${bonus.id}: ${bonus.value})`;
            } else {
                return ` (${bonus.type}: ${bonus.value})`;
            }
        }).join(', ');
    };

    return (
        <div>
            {/*<Typography variant="h5">Spellcrafting</Typography>*/}
            {!selectedSlot ? (
                <Typography color="error">Please select an item slot first.</Typography>
            ) : isNonSpellcraftingSlot ? (
                // Display a message if the selected slot is not available for spellcrafting
                <Typography color="error">Spellcrafting is not available for the selected slot.</Typography>
            ) : (
                <>
                <Grid container spacing={2} alignItems="center" mb={2} mt={.2}>

                {isWeaponSlot ? (
                        // UI for weapon selection
                        <>
                            <Grid item>
                            <Select
                                value={selectedWeaponType}
                                onChange={handleWeaponTypeChange} // Updated to use handleWeaponTypeChange
                                sx={{ minWidth: 150 }}
                            >
                                {Object.keys(spellcraftingWeapons[realm]).map((type, index) => (
                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                            </Grid>
                            {selectedWeaponType === 'Shield' && (
                                <Grid item>
                                <Select
                                    value={selectedShieldSize}
                                    onChange={handleShieldSizeChange}
                                    sx={{ minWidth: 125 }}
                                >
                                    {['Small', 'Medium', 'Large'].map((size, index) => (
                                        <MenuItem key={index} value={size}>{size}</MenuItem>
                                    ))}
                                </Select>
                                </Grid>
                            )}
                            <Grid item>
                            <Select
                                value={selectedWeapon}
                                onChange={(e) => setSelectedWeapon(e.target.value)}
                                sx={{ minWidth: 380 }}
                            >
                                {selectedWeaponType && (
                                    selectedWeaponType === 'Shield' ?
                                        (spellcraftingWeapons[realm]?.Shield?.[selectedShieldSize || ''] &&
                                            Object.keys(spellcraftingWeapons[realm].Shield[selectedShieldSize || '']).map((weapon, index) => (
                                                <MenuItem key={index} value={weapon}>
                                                    {weapon}{getWeaponBonusDisplay(weapon)}
                                                </MenuItem>
                                            ))
                                        ) : (
                                            spellcraftingWeapons[realm]?.[selectedWeaponType] &&
                                            Object.keys(spellcraftingWeapons[realm][selectedWeaponType]).map((weapon, index) => (
                                                <MenuItem key={index} value={weapon}>
                                                    {weapon}{getWeaponBonusDisplay(weapon)}
                                                </MenuItem>
                                            ))
                                        )
                                )}
                            </Select>
                                </Grid>
                        </>
                    ) : (
                        // UI for armor selection
                        <>
                            <Grid item>
                            <Select
                                value={selectedArmorType}
                                onChange={handleArmorTypeChange}
                                sx={{ minWidth: 125 }}
                            >
                                {Object.keys(spellcraftingArmor[template.realm]).map((type, index) => (
                                    // This maps over each armor type in the current realm and creates a menu item for it
                                    <MenuItem key={index} value={type}>{type}</MenuItem>
                                ))}
                            </Select>
                            </Grid>
                            {selectedArmorType && (
                                <Grid item>
                                <Select
                                    value={selectedArmorPiece}
                                    onChange={(e) => setSelectedArmorPiece(e.target.value)}
                                    sx={{ minWidth: 275 }}
                                >
                                    {selectedArmorType && spellcraftingArmor[realm][selectedArmorType][selectedSlot]
                                        ? Object.keys(spellcraftingArmor[realm][selectedArmorType][selectedSlot]).map((armorName, index) => (
                                            <MenuItem key={index} value={armorName}>
                                                {armorName}{getArmorBonusDisplay(armorName)}
                                            </MenuItem>
                                        ))
                                        : <MenuItem value="">No options available</MenuItem>
                                    }
                                </Select>
                                </Grid>
                            )}

                        </>
                    )}
                </Grid>
                    {/* UI for gem slots */}
                    {gems.map((gem, index) => (
                        <Grid container mb={2} spacing={2} key={index}>
                            <Grid item>
                                <Select
                                    value={gem.type}
                                    onChange={(e) => handleGemChange(index, 'type', e.target.value)}
                                    sx={{ minWidth: 125 }}
                                >
                                    {Object.keys(gemData[realm]).map((type, typeIndex) => (
                                        <MenuItem key={typeIndex} value={type}>{type}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            {gemData[realm][gem.type]?.id && (
                                <Grid item>
                                    <Select
                                        value={gem.id}
                                        onChange={(e) => handleGemChange(index, 'id', e.target.value)}
                                        sx={{ minWidth: 200 }}
                                    >
                                        {gemData[realm][gem.type].id.map((id, idIndex) => (
                                            <MenuItem key={idIndex} value={id}>{id}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            )}

                            <Grid item>
                                <Select
                                    value={gem.value}
                                    onChange={(e) => handleGemChange(index, 'value', e.target.value)}
                                    sx={{ minWidth: 75 }}
                                >
                                    {gemData[realm][gem.type]?.values?.map((value, valueIndex) => (
                                        <MenuItem key={valueIndex} value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            {gems.length > 1 && (
                                <Grid item>
                                    <Button onClick={() => removeGemSlot(index)}>Remove</Button>
                                </Grid>
                            )}
                        </Grid>
                    ))}

                    {/* Button to add a new gem slot */}
                    {gems.length < 4 && (
                        <Button onClick={addGemSlot}>Add Gem Slot</Button>
                    )}

                    {/* Display imbue points and crafted gear preview */}
                    <>
                        <Typography variant="h6" mb={1}>
                            Imbue Points: <span style={{ color: currentImbuePoints > maxImbuePoints ? 'red' : 'inherit' }}>{currentImbuePoints}</span> / {maxImbuePoints}
                        </Typography>
                        <Button variant="contained" onClick={() => handleAddGearToSlot(true)} sx={{ marginRight: 2 }}>Add Crafted Gear to {selectedSlot}</Button>
                        <Button variant="outlined" color="secondary" onClick={handleAddGearToInventory}>Add to Inventory</Button>                        {/*<Typography variant="h6">Crafted Gear Preview:</Typography>*/}
                        {/*<pre>{createCraftedGearJson()}</pre>*/}
                    </>
                </>
            )}
        </div>
    );
};

export default Spellcrafting;
