import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import GearList from "../../GearList";
import { calculateStats } from "../../../utility/calculateGearStats";
import TemplateEquipment from "./TemplateEquipment";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ItemDetails from "../shared/ItemDetails";
import TemplateInventory from "./TemplateInventory";
import TemplateStatisticsNew from "../shared/TemplateStatisticsNew";
import TemplateResistancesNew from "../shared/TemplateResistancesNew";
import TemplateSkillsNew from "../shared/TemplateSkillsNew";
import TemplateBonusesNew from "../shared/TemplateBonusesNew";
import CharacterStatistics from "../old/CharacterStatistics";
import CharacterResistances from "../old/CharacterResistances";
import CharacterSkills from "../old/CharacterSkills";
import CharacterBonuses from "../old/CharacterBonuses";

const TemplateContainer = ({ template, gearBySlot, setGearBySlot, inventory, setInventory }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [weaponConfig, setWeaponConfig] = useState('Main Hand / Off Hand');
    const [selectedGear, setSelectedGear] = useState({});
    const [statistics, setStatistics] = useState({});
    const [viewPreference, setViewPreference] = useState('Table view');
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const storedPreference = localStorage.getItem('viewPreference');
        if (storedPreference) {
            setViewPreference(storedPreference);
        }
    }, []);

    useEffect(() => {
        const statsResult = calculateStats(gearBySlot, weaponConfig);
        setStatistics(statsResult);
    }, [gearBySlot, weaponConfig]);

    const handleItemClick = (itemName) => {
        if (selectedItem === itemName) {
            setSelectedItem(null);
            setSelectedSlot(null);
        } else {
            setSelectedItem(itemName);
            setSelectedSlot(normalizeSlotName(itemName));
            const slotName = normalizeSlotName(itemName);
            setSelectedGear({ ...selectedGear, [slotName]: itemName });
        }
    };

    const addGearToSlot = (slot, gear, setActive = false) => {
        setInventory(prevInventory => {
            const updatedInventory = { ...prevInventory };
            if (!updatedInventory[slot]) {
                updatedInventory[slot] = [];
            }
            if (!updatedInventory[slot].some(item => item.id === gear.id)) {
                updatedInventory[slot].push(gear);
            }
            return updatedInventory;
        });

        if (setActive) {
            setGearBySlot(prevGearBySlot => {
                const updatedGearBySlot = { ...prevGearBySlot };
                const oldItem = updatedGearBySlot[slot];
                updatedGearBySlot[slot] = gear;

                if (oldItem) {
                    setInventory(prevInventory => {
                        const updatedInventory = { ...prevInventory };
                        updatedInventory[slot] = updatedInventory[slot].filter(item => item.id !== oldItem.id);
                        return updatedInventory;
                    });
                }

                return updatedGearBySlot;
            });
        }
    };

    const selectItemFromInventory = (slot, itemId) => {
        const selectedItem = inventory[slot].find(item => item.id === itemId);
        if (selectedItem) {
            setGearBySlot(prevGearBySlot => ({ ...prevGearBySlot, [slot]: selectedItem }));
        }
    };

    const removeItemFromInventory = (slot, itemId) => {
        setInventory(prevInventory => {
            const updatedInventory = { ...prevInventory };
            updatedInventory[slot] = updatedInventory[slot].filter(item => item.id !== itemId);
            return updatedInventory;
        });

        if (gearBySlot[slot]?.id === itemId) {
            removeGearFromSlot(slot);
        }
    };

    const removeGearFromSlot = (slot) => {
        setGearBySlot(prevGearBySlot => {
            const updatedGearBySlot = { ...prevGearBySlot };
            delete updatedGearBySlot[slot];
            return updatedGearBySlot;
        });
    };

    const normalizeSlotName = (slotName) => {
        if (slotName === 'L Ring' || slotName === 'R Ring') return 'Ring';
        if (slotName === 'L Wrist' || slotName === 'R Wrist') return 'Bracer';
        return slotName;
    };

    const renderItemDetails = () => {
        const item = gearBySlot[selectedItem]; // Use selectedItem here to display the item details
        if (!item) {
            return <Typography variant="body1">No item selected.</Typography>;
        }
        return <ItemDetails item={item} />;
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={7} md={6} lg={3} xl={2.3}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TemplateEquipment
                        gearBySlot={gearBySlot}
                        selectedSlot={selectedSlot}
                        selectedItem={selectedItem}
                        handleItemClick={handleItemClick}
                        weaponConfig={weaponConfig}
                        setWeaponConfig={setWeaponConfig}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={5} md={6} lg={3} xl={2}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TemplateInventory
                        inventory={inventory}
                        selectedSlot={selectedSlot}
                        selectedItem={selectedItem}
                        selectItemFromInventory={selectItemFromInventory}
                        removeItemFromInventory={removeItemFromInventory}
                        renderItemDetails={renderItemDetails}
                    />

                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2}>
                        {selectedItem && (
                            <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                                {renderItemDetails()}
                            </Paper>
                        )}
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <GearList
                        selectedSlot={selectedSlot}
                        selectedItem={selectedItem}
                        gearBySlot={gearBySlot}
                        template={template}
                        addGearToSlot={addGearToSlot}
                        removeGearFromSlot={removeGearFromSlot}
                        renderItemDetails={renderItemDetails}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={3.7}>
                <Grid container spacing={2} style={{ display: isLargeScreen ? 'flex' : 'block' }}>
                    {statistics && (
                        viewPreference === 'Table view' ? (
                            <React.Fragment>
                                <Grid item xs={12} sm={12} md={6} lg={5} xl={12}>
                                    <TemplateStatisticsNew statistics={statistics} selectedClass={template.class} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={7}>
                                    <TemplateResistancesNew statistics={statistics} selectedClass={template.class} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={5}>
                                    <TemplateSkillsNew statistics={statistics} selectedClass={template.class} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <TemplateBonusesNew statistics={statistics} selectedClass={template.class} />
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid item xs={12} sm={12} md={6} lg={5} xl={12}>
                                    <CharacterStatistics statistics={statistics} selectedClass={template.class} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={4} xl={7}>
                                    <CharacterResistances statistics={statistics} selectedClass={template.class} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={3} xl={5}>
                                    <CharacterSkills statistics={statistics} selectedClass={template.class} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CharacterBonuses statistics={statistics} selectedClass={template.class} />
                                </Grid>
                            </React.Fragment>
                        )
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TemplateContainer;
