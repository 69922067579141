import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, Select, MenuItem, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuthContext } from '../../hooks/useAuthContext';
import { db } from '../../firebase/config';
import { collection, addDoc, deleteDoc, doc } from 'firebase/firestore';

const CharactersList = ({ characters, selectedRealm, onRealmChange, onCharacterSelect, selectedCharacterId, refreshCharacters }) => {
    const { user } = useAuthContext();
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [server, setServer] = useState(''); // New state for server
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Can be 'error', 'warning', 'info', or 'success'

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCreateCharacter = async () => {
        if (!user) return;
        try {
            // Fetch character_web_id from API
            const searchResponse = await fetch(`https://api.camelotherald.com/character/search?cluster=${server}&name=${encodeURIComponent(name)}`);
            const searchData = await searchResponse.json();

            const matchedCharacter = searchData.results.find(character => {
                const [firstName] = character.name.split(' ');
                return firstName.toLowerCase() === name.toLowerCase();
            });

            if (matchedCharacter) {
                const characterId = matchedCharacter.character_web_id;
                const characterInfoResponse = await fetch(`https://api.camelotherald.com/character/info/${characterId}`);
                const characterInfo = await characterInfoResponse.json();

                const newCharacter = {
                    name,
                    server,
                    characterId,
                    class: characterInfo.class_name,
                    realm: realmMapping[characterInfo.realm]
                };

                await addDoc(collection(db, `users/${user.uid}/characters`), newCharacter);
                refreshCharacters();
                handleClose();

                setSnackbarMessage('Character created successfully.');
                setSnackbarSeverity('success');
            } else {
                setSnackbarMessage('Character not found.');
                setSnackbarSeverity('error');
            }
        } catch (error) {
            console.error('Error creating character:', error);
            setSnackbarMessage('Error creating character.');
            setSnackbarSeverity('error');
        }
        setSnackbarOpen(true);
    };

    const handleDeleteCharacter = async (characterId) => {
        if (!user) return;
        try {
            await deleteDoc(doc(db, `users/${user.uid}/characters`, characterId));
            refreshCharacters(); // Refresh character list after deletion
        } catch (error) {
            console.error('Error deleting character:', error);
        }
    };

    const handleCharacterSelect = (character) => {
        onCharacterSelect(character.id === selectedCharacterId ? null : character);
    };

    const realmMapping = {
        1: 'Albion',
        2: 'Midgard',
        3: 'Hibernia'
    };

    return (
        <div>
            <Box mb={2}>
                <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                    Create New Character
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create New Character</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        To create a new character, please fill out the following details.
                    </DialogContentText>
                    <Box mb={2}>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Character Name"
                            type="text"
                            fullWidth
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Box>
                    <Box mb={2}>
                        <Select
                            value={server}
                            onChange={(e) => setServer(e.target.value)}
                            fullWidth
                            margin="dense"
                        >
                            <MenuItem value=''>Select Server</MenuItem>
                            <MenuItem value='Ywain'>Ywain</MenuItem>
                            <MenuItem value='Gaheris'>Gaheris</MenuItem>
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCreateCharacter} color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Box mb={2}>
                <Select value={selectedRealm} onChange={onRealmChange} fullWidth>
                    <MenuItem value=''>All Realms</MenuItem>
                    <MenuItem value='Albion'>Albion</MenuItem>
                    <MenuItem value='Midgard'>Midgard</MenuItem>
                    <MenuItem value='Hibernia'>Hibernia</MenuItem>
                </Select>
            </Box>
            <List dense>
                {characters.map(character => (
                    <ListItem
                        key={character.id}
                        button
                        selected={character.id === selectedCharacterId}
                        onClick={() => handleCharacterSelect(character)}
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCharacter(character.id)}>
                                <DeleteIcon />
                            </IconButton>
                        }
                    >
                        <ListItemText primary={character.name} secondary={`${character.class} (${character.server})`} />
                    </ListItem>
                ))}
            </List>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
            >
                <Alert
                    onClose={() => setSnackbarOpen(false)}
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default CharactersList;
