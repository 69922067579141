import { auth, db } from '../firebase/config';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useAuthContext } from './useAuthContext';
import axios from 'axios';

const useSignup = () => {
    const { dispatch } = useAuthContext();

    const signup = async (email, password, displayName) => {
        try {
            // Create a new user with email and password
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Update the user's profile with the displayName
            await updateProfile(user, { displayName: displayName });

            // Retrieve the ID token
            const token = await user.getIdToken();
            localStorage.setItem('authToken', token);

            // Create a user document in Firestore with the displayName
            const userDocRef = doc(db, 'users', user.uid);
            await setDoc(userDocRef, {
                displayName: displayName,
                uid: user.uid, // Store the user's UID
            });

            // Create the user in your database
            const userPayload = { uid: user.uid, displayName: displayName };
            try {
                await axios.post('http://localhost:8080/users/create', userPayload, {
                    headers: { Authorization: `Bearer ${token}` }
                });
            } catch (postError) {
                console.error('Error creating user in database:', postError.response ? postError.response.data : postError.message);
            }

            // Dispatch the LOGIN action to update the user state in AuthContext
            dispatch({ type: 'LOGIN', payload: { user, token } });

            // You can also perform additional actions after successful signup if needed
            // For example, you can redirect the user to a different page.
        } catch (error) {
            // Handle signup errors here (e.g., show an error message)
            console.error('Signup error:', error.message);
            throw error;
        }
    };

    return { signup };
};

export default useSignup;
