import React, { useState, useEffect } from 'react';
import {useAuthContext} from "../../hooks/useAuthContext";
import {doc, getDoc} from "firebase/firestore";
import {db} from "../../firebase/config";
import {useParams} from "react-router-dom";
import {Typography} from "@mui/material";
import TemplateEdit from "../../components/templates/edit/TemplateEdit";
import TemplateView from "../../components/templates/view/TemplateView";

const Template = () => {
    const { realm, class: className, id } = useParams();
    const { user } = useAuthContext();
    const [template, setTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const templateDocRef = doc(db, `templates`, id);
                const templateDocSnapshot = await getDoc(templateDocRef);

                if (templateDocSnapshot.exists()) {
                    const fetchedTemplate = templateDocSnapshot.data();
                    setTemplate(fetchedTemplate);
                    if (!fetchedTemplate.isPrivate || fetchedTemplate.owner === user?.uid || fetchedTemplate.sharedUsers.some(u => u.uid === user?.uid)) {
                        setHasAccess(true);
                    }
                } else {
                    console.log('Template not found.');
                }
            } catch (error) {
                console.error('Error fetching template:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchTemplate();
    }, [realm, className, id, user?.uid]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (!template) {
        return <Typography>Template not found.</Typography>;
    }

    if (!hasAccess) {
        return <Typography>You do not have access to view this template.</Typography>;
    }

    if (template.owner === user?.uid) {
        return (
            <TemplateEdit />
        )
    }

    if (hasAccess) {
        return (
            <TemplateView />
        )
    }

    return (
        <dive>Something went really really wrong.</dive>
    )
}

export default Template;
