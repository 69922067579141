import bonusTypesMetadata from '../data/daoc_item_metadata.json';

function calculateSingleItemUtility(item) {
    const bonusNameToMeta = {};
    for (const [key, meta] of Object.entries(bonusTypesMetadata.bonus_types)) {
        bonusNameToMeta[meta.name] = meta;
    }

    let itemUtility = 0;

    if ('bonuses' in item) {
        item.bonuses.forEach(bonus => {
            const bonusMeta = bonusNameToMeta[bonus.type] || {};
            let utilityValue = bonusMeta.utility || 0;

            if (bonusMeta.has_sub_type && typeof bonus.id === 'string') {
                const subTypes = bonusMeta.sub_types || {};
                if (bonus.type === "Toa Overcap") {
                    utilityValue = subTypes[bonus.id] || utilityValue;
                } else {
                    utilityValue = subTypes[String(bonus.id)] || utilityValue;
                }
            }
            itemUtility += utilityValue * bonus.value;
        });
    }

    return Math.round(itemUtility * 100) / 100;
}

export default calculateSingleItemUtility;
