import React from 'react';
import { Paper, Typography, Tooltip, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const classRestrictedStats = {
    'Armsman': ['Piety', 'Empathy', 'Intelligence', 'Acuity', 'Charisma'],
    'Cabalist': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Cleric': ['Strength', 'Quickness', 'Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Friar': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Heretic': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Infiltrator': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Mauler': ['Intelligence', 'Empathy', 'Piety', 'Charisma', 'Acuity'],
    'Mercenary': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Minstrel': ['Intelligence', 'Piety', 'Empathy','Acuity'],
    'Necromancer': ['Strength', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Paladin': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Reaver': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Scout': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Sorcerer': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Theurgist': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Wizard': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Berserker': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Bonedancer': ['Strength', 'Quickness', 'Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Healer': ['Strength', 'Quickness', 'Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Hunter': ['Intelligence', 'Empathy', 'Piety', 'Charisma', 'Acuity'],
    'Runemaster': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Savage': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Shadowblade': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Shaman': ['Strenght', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Skald': ['Intelligence', 'Piety', 'Empathy', 'Acuity'],
    'Spiritmaster': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Thane': ['Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Valkyrie': ['Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Warlock': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Warrior': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Animist': ['Strength', 'Quickenss', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Bainshee': ['Strength', 'Quickenss', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Bard': ['Intelligence', 'Piety', 'Empathy', 'Acuity'],
    'Blademaster': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Champion': ['Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Druid': ['Strength', 'Quickness', 'Intelligence', 'Piety', 'Charisma', 'Acuity'],
    'Eldritch': ['Strength', 'Quickenss', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Enchanter': ['Strength', 'Quickenss', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Hero': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Mentalist': ['Strength', 'Quickenss', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Nightshade': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Ranger': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Valewalker': ['Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Vampiir': ['Intelligence', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Warden': ['Intelligence', 'Piety', 'Charisma', 'Acuity']
};

const CharacterStatistics = ({ statistics, selectedClass }) => {
    const theme = useTheme();
    const restrictedStats = classRestrictedStats[selectedClass] || [];
    const statKeys = ['Strength', 'Constitution', 'Dexterity', 'Quickness', 'Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'];
    const bonusKeys = ['Hit Points', 'Power', 'Armor Factor (AF)'];

    const CustomTooltip = ({ title, children }) => (
        <Tooltip
            title={
                <Box style={{ padding: 8, fontSize: '0.875rem' }}>
                    {title.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </Box>
            }
            arrow
        >
            {children}
        </Tooltip>
    );

    const renderValue = (value, baseCap, cap, mythicalCap, isStat) => {
        let totalCap = isStat ? baseCap + cap + mythicalCap : baseCap + mythicalCap;
        totalCap = Math.min(totalCap, 127); // Ensure the totalCap does not exceed 127 for stats

        const percentageOfValue = (value / totalCap) * 100; // Compare value to its own cap
        const percentageOfCap = (totalCap / 127) * 100; // Compare total cap to 127

        let color;
        if (percentageOfValue >= 100 && percentageOfCap >= 100) {
            color = theme.palette.primary.main; // Use primary color
        } else if (percentageOfValue >= 90) {
            color = 'green';
        } else if (percentageOfValue >= 80) {
            color = 'orange';
        } else {
            color = 'red';
        }

        const tooltipTitle = `${isStat ? `Cap: ${cap}` : ''}, Mythical Cap: ${mythicalCap}`;

        return (
            <CustomTooltip title={tooltipTitle}>
                <Typography variant="body2" style={{ color }}>
                    {value}/{totalCap}
                </Typography>
            </CustomTooltip>
        );
    };

    const renderBonus = (key) => {
        const bonus = statistics[key];
        if (!bonus) return null;

        let baseCap = 0; // Default base cap
        let maxCap = 50; // Default max cap for most bonuses

        if (key === 'Hit Points') {
            baseCap = 200; // Base cap specifically for Hit Points
            maxCap = 600; // Adjust maxCap if needed for hit points
        }

        let displayCap = bonus.cap + baseCap; // Add the baseCap to the display cap
        let totalCap = Math.min(maxCap, displayCap + bonus.mythicalCap); // Consider the sum of cap and mythical cap

        const percentageOfValue = (bonus.value / totalCap) * 100; // Percentage based on the total cap

        let color;
        if (percentageOfValue >= 100) {
            color = theme.palette.primary.main; // Use primary color
        } else if (percentageOfValue >= 90) {
            color = 'green';
        } else if (percentageOfValue >= 80) {
            color = 'orange';
        } else {
            color = 'red';
        }

        const tooltipTitle = `Value: ${bonus.value}, Cap: ${displayCap} (Base: ${baseCap}, Mythical: ${bonus.mythicalCap})`;

        return (
            <div key={key} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body2">{bonus.displayName}:</Typography>
                <CustomTooltip title={tooltipTitle}>
                    <Typography variant="body2" style={{ color }}>
                        {bonus.value}/{totalCap}
                    </Typography>
                </CustomTooltip>
            </div>
        );
    };


    const renderRow = (key, isStat) => {
        if (restrictedStats?.includes(key) || !statistics[key]) return null;
        const { value, cap, mythicalCap } = statistics[key];
        const baseCap = isStat ? 75 : 26;

        return (
            <div key={key} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body2">{key}:</Typography>
                {renderValue(value, baseCap, cap, mythicalCap, isStat)}
            </div>
        );
    };

    return (
        <Paper style={{ padding: '16px', flex: 1, height: '100%' }}>
            <Typography variant="body1">Statistics</Typography>
            <hr/>
            <div>
                {statKeys.map(key => renderRow(key, true))}
                {bonusKeys.map(renderBonus)}
            </div>
        </Paper>
    );
};

export default CharacterStatistics;
