import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../../firebase/config';
import { useAuthContext } from "../../../hooks/useAuthContext";
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import {
    Box,
    Typography,
    Grid,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import TemplateContainerViewOnly from "./TemplateContainerViewOnly";
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import TextField from "@mui/material/TextField";
import TemplateExportButton from "./TemplateExportButton";

const TemplateView = () => {
    const { realm, class: className, id } = useParams();
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [template, setTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);
    const [copyDialogOpen, setCopyDialogOpen] = useState(false);
    const [newTemplateName, setNewTemplateName] = useState('');
    const [statistics, setStatistics] = useState({}); // State to hold statistics

    useEffect(() => {
        const fetchTemplate = async () => {
            try {
                const templateDocRef = doc(db, `templates`, id);
                const templateDocSnapshot = await getDoc(templateDocRef);

                if (templateDocSnapshot.exists()) {
                    const fetchedTemplate = templateDocSnapshot.data();
                    setTemplate(fetchedTemplate);
                    if (!fetchedTemplate.isPrivate || fetchedTemplate.owner === user?.uid || fetchedTemplate.sharedUsers.some(u => u.uid === user?.uid)) {
                        setHasAccess(true);
                    }
                } else {
                    console.log('Template not found.');
                }
            } catch (error) {
                console.error('Error fetching template:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchTemplate();
    }, [realm, className, id, user?.uid]);

    const handleLike = async () => {
        if (!user) return;
        if (template && user) {
            const currentLikes = template.likes || [];
            const updatedLikes = currentLikes.includes(user.uid) ?
                currentLikes.filter(uid => uid !== user.uid) :
                [...currentLikes, user.uid];

            const templateDocRef = doc(db, `templates`, id);
            await updateDoc(templateDocRef, { likes: updatedLikes });
            setTemplate({ ...template, likes: updatedLikes });
        }
    };

    const handleOpenCopyDialog = () => {
        setCopyDialogOpen(true);
    };

    const handleCloseCopyDialog = () => {
        setCopyDialogOpen(false);
        setNewTemplateName('');
    };

    const handleCopyTemplate = async () => {
        if (!user || !template) return;
        handleCloseCopyDialog();

        const currentDate = new Date();
        try {
            const newTemplate = {
                ...template,
                name: newTemplateName,
                owner: user.uid,
                ownerName: user.displayName,
                isPrivate: true,
                createdOn: currentDate,
                lastUpdated: currentDate,
            };
            delete newTemplate.id;

            const newTemplateRef = await addDoc(collection(db, 'templates'), newTemplate);
            navigate(`/templates/${newTemplateRef.id}`);
        } catch (error) {
            console.error('Error copying template:', error);
        }
    };

    const handleEditClick = () => {
        if (!user) return;
        navigate(`/templates/${id}`);
    };

    const handleStatisticsCalculated = (calculatedStatistics) => {
        setStatistics(calculatedStatistics);
    };

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (!template) {
        return <Typography>Template not found.</Typography>;
    }

    if (!hasAccess) {
        return <Typography>You do not have access to view this template.</Typography>;
    }

    const isLikedByUser = template.likes ? template.likes.includes(user?.uid) : false;

    return (
        <React.Fragment>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12} sm={4} md={3} lg={3} xl={3}>
                    <Typography variant="body1">{template.name}</Typography>
                </Grid>

                <Grid container item xs={12} sm={8} md={9} lg={5} xl={5} justifyContent="space-between" alignItems="center">
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography variant="body1">
                            <strong>Owner:</strong> {template.ownerName}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography variant="body1">
                            <strong>Realm:</strong> {template.realm}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={2}>
                        <Typography variant="body1">
                            <strong>Class:</strong> {template.class}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={3} xl={3}>
                        <Typography variant="body1">
                            <strong>Level:</strong> {template.level}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={12} sm={12} md={12} lg={3} xl={3} justifyContent="flex-end" alignItems="center">
                    <Grid item xs={9} sm={9} md={9} lg={6} xl={6} sx={{ order: { sm: 1, md: 1, lg: 1, xl: 1 } }}>
                        <Typography variant="body1">
                            <strong>Status:</strong> {template.isPrivate ? "Private" : "Public"}
                        </Typography>
                    </Grid>

                    <Grid item xs={3} sm={3} md={3} lg={6} xl={6} sx={{ order: { sm: 2, md: 2, lg: 2, xl: 2 } }}>
                        <Box display="flex" justifyContent="flex-end">
                            {!template.isPrivate && (
                                <Box ml={2}>
                                    <TemplateExportButton template={template} statistics={statistics} id={id} />
                                </Box>
                            )}
                            {user && user.uid === template.owner && (
                                <Box ml={2}>
                                    <Button variant="contained" color="primary" onClick={handleEditClick}>
                                        Edit
                                    </Button>
                                </Box>
                            )}
                            {user && !template.isPrivate && (
                                <Box ml={2}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleOpenCopyDialog}
                                        startIcon={<FileCopyIcon />}
                                    >
                                        Copy
                                    </Button>
                                </Box>
                            )}
                            {user && user.uid !== template.owner && (
                                <Box ml={2}>
                                    <Button
                                        variant="contained"
                                        color={isLikedByUser ? "secondary" : "primary"}
                                        onClick={handleLike}
                                        startIcon={isLikedByUser ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                                    >
                                        {isLikedByUser ? "Unlike" : "Like"}
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>

            </Grid>

            <hr />
            <Grid container spacing={2} mt={2} justifyContent="space-between" alignItems="center">
                <TemplateContainerViewOnly
                    template={template}
                    gearBySlot={template.items}
                    onStatisticsCalculated={handleStatisticsCalculated}
                />
            </Grid>
            {/* Copy Template Dialog */}
            <Dialog open={copyDialogOpen} onClose={handleCloseCopyDialog}>
                <DialogTitle>Copy Template</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter a name for the new template:
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Template Name"
                        type="text"
                        fullWidth
                        value={newTemplateName}
                        onChange={(e) => setNewTemplateName(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseCopyDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleCopyTemplate} color="primary">
                        Copy
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default TemplateView;
