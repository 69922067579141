import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid } from '@mui/material';
import baseStatsData from "../../data/classes/baseStats";

const Stats = ({ characterData, updateCharacterData }) => {
    const [attributes, setAttributes] = useState({});
    const [pointsRemaining, setPointsRemaining] = useState(30);

    useEffect(() => {
        if (characterData?.race) {
            if (Object.keys(characterData.baseStats).length === 0 || characterData.race !== characterData.baseStatsRace) {
                // New race selected, initialize attributes
                const initialAttributes = baseStatsData[characterData.race] || {};
                setAttributes(initialAttributes);

                // Calculate points spent and set the remaining points
                const pointsSpent = calculatePointsSpent(initialAttributes);
                const remainingPoints = 30 - pointsSpent;
                setPointsRemaining(remainingPoints);

                // Update character data with the initial attributes
                updateCharacterData((prevData) => ({
                    ...prevData,
                    baseStats: initialAttributes,
                    baseStatsRace: characterData.race, // Track the race used for baseStats
                    pointsRemaining: remainingPoints,
                }));
            } else {
                // Use existing stats and points remaining if they are already set
                setAttributes(characterData.baseStats);
                setPointsRemaining(characterData.pointsRemaining || 30);
            }
        }
    }, [characterData.race]);

    const calculatePointsSpent = (attributes) => {
        const initialAttributes = baseStatsData[characterData.race] || {};
        return Object.entries(attributes).reduce((totalSpent, [attr, value]) => {
            const currentBase = initialAttributes[attr] || 0;
            const difference = value - currentBase;
            if (difference > 15) return totalSpent + (3 * (value - (currentBase + 15))) + (2 * 5) + 5;
            if (difference > 10) return totalSpent + (2 * (value - (currentBase + 10))) + 5;
            if (difference > 0) return totalSpent + difference;
            return totalSpent;
        }, 0);
    };

    const handleIncrement = (attr) => {
        const currentBase = baseStatsData[characterData.race][attr];
        const currentValue = attributes[attr] - currentBase;
        const cost = currentValue >= 15 ? 3 : currentValue >= 10 ? 2 : 1;

        if (pointsRemaining >= cost) {
            const newAttributes = { ...attributes, [attr]: attributes[attr] + 1 };
            const newPointsRemaining = pointsRemaining - cost;
            setAttributes(newAttributes);
            setPointsRemaining(newPointsRemaining);

            updateCharacterData((prevData) => ({
                ...prevData,
                baseStats: { ...prevData.baseStats, [attr]: newAttributes[attr] },
                pointsRemaining: newPointsRemaining,
            }));
        }
    };

    const handleDecrement = (attr) => {
        const currentBase = baseStatsData[characterData.race][attr];

        if (attributes[attr] > currentBase) {
            const currentValue = attributes[attr] - currentBase;
            const cost = currentValue > 15 ? 3 : currentValue > 10 ? 2 : 1;

            const newAttributes = { ...attributes, [attr]: attributes[attr] - 1 };
            const newPointsRemaining = pointsRemaining + cost;
            setAttributes(newAttributes);
            setPointsRemaining(newPointsRemaining);

            updateCharacterData((prevData) => ({
                ...prevData,
                baseStats: { ...prevData.baseStats, [attr]: newAttributes[attr] },
                pointsRemaining: newPointsRemaining,
            }));
        }
    };

    const resetAttributes = () => {
        const initialAttributes = baseStatsData[characterData.race];
        setAttributes(initialAttributes);
        setPointsRemaining(30);

        updateCharacterData((prevData) => ({
            ...prevData,
            baseStats: initialAttributes,
            pointsRemaining: 30,
        }));
    };

    if (!characterData.race) {
        return <Typography variant="h6" color="primary">Please select a race to view and edit stats.</Typography>;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom color="primary">Character Base Attributes</Typography>
            <Grid container spacing={.5} direction="column">
                {Object.entries(attributes).map(([key, value]) => {
                    const currentBase = baseStatsData[characterData.race][key];
                    const isIncreased = value > currentBase;

                    return (
                        <Grid item key={key} container alignItems="center">
                            <Typography component="div" sx={{ width: '20%', color: 'primary.main' }}>{key}:</Typography>
                            <Button onClick={() => handleDecrement(key)}>-</Button>
                            <Typography
                                component="div"
                                sx={{
                                    width: '15%',
                                    color: isIncreased ? 'secondary.main' : 'primary.main',
                                    textAlign: 'center',
                                }}
                            >
                                {value}
                            </Typography>
                            <Button onClick={() => handleIncrement(key)}>+</Button>
                        </Grid>
                    );
                })}
            </Grid>
            <Button onClick={resetAttributes} color="secondary">Reset Attributes</Button>
            <Typography variant="h6" color="primary">Points Remaining: {pointsRemaining}</Typography>
        </div>
    );
};

export default Stats;
