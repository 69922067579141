import React, { useState, useEffect } from 'react';
import { TextField, Grid, Typography, Button, Select, MenuItem } from '@mui/material';
import customItemData from '../../data/customItemData.json';

const CustomItem = ({ template, selectedSlot, selectedItem, addGearToSlot, gearBySlot }) => {
    const [itemName, setItemName] = useState('');
    const [bonuses, setBonuses] = useState([]);
    const [itemNameError, setItemNameError] = useState(false);


    useEffect(() => {
        if (gearBySlot[selectedItem] && gearBySlot[selectedItem].tags && gearBySlot[selectedItem].tags.includes('custom')) {
            // Populate with existing custom item data
            setItemName(gearBySlot[selectedItem].name || '');
            setBonuses(gearBySlot[selectedItem].bonuses || []);
        } else {
            // Reset for new custom item
            setItemName('');
            setBonuses([]);
        }
    }, [gearBySlot, selectedItem]);

    const getBonusTypes = () => Object.keys(customItemData[template.realm] || {});
    const getBonusIds = (type) => customItemData[template.realm][type] || [];

    const addBonus = () => {
        const bonusTypes = getBonusTypes();
        const initialType = bonusTypes[0];
        const initialIds = getBonusIds(initialType);
        setBonuses([...bonuses, { type: initialType, id: initialIds.length ? initialIds[0] : undefined, value: '' }]);
    };

    const handleBonusChange = (index, field) => (event) => {
        const newBonuses = [...bonuses];
        let newValue = event.target.value;

        // Convert the value to an integer if the field being changed is 'value'
        if (field === 'value') {
            newValue = newValue === '' ? '' : parseInt(newValue, 10) || 0;
        }

        newBonuses[index][field] = newValue;

        // Initialize or remove 'id' if bonus type changes
        if (field === 'type') {
            const newIds = getBonusIds(event.target.value);
            newBonuses[index].id = newIds.length ? newIds[0] : undefined;
        }
        setBonuses(newBonuses);
    };

    const removeBonus = (index) => setBonuses(bonuses.filter((_, i) => i !== index));

    const generateUniqueId = () => {
        return Date.now().toString(36) + Math.random().toString(36).slice(2);
    };

    const handleAddCustomGearToSlot = (activateItem = true) => {
        if (!selectedSlot) {
            console.log("Please select a slot.");
            return;
        }

        if (!itemName) {
            // Set error state if itemName is empty
            setItemNameError(true);
            console.log("Item name is required.");
            return;
        }

        if (selectedSlot && itemName) {
            // Filter out bonuses with empty or invalid values
            const validBonuses = bonuses.filter(bonus => bonus.value !== '' && bonus.value != null).map(bonus => {
                // Assuming you still want to keep the structure of bonuses that have valid values
                return bonus.id === undefined || bonus.id === '' ? { type: bonus.type, value: bonus.value } : bonus;
            });

            const customGear = {
                id: generateUniqueId(),
                name: itemName,
                slot: selectedItem,
                realm: template.realm,
                bonuses: validBonuses,
                tags: ["custom"]
            };
            addGearToSlot(selectedItem, customGear, activateItem);
            setBonuses([]);
            setItemName('');
        } else {
            console.log("Please select a slot and enter an item name.");
        }
    };




    return (
        <div>
            {/*<Typography variant="h5">Custom Item</Typography>*/}
            {!selectedSlot && <Typography color="error">Please select an item slot first.</Typography>}
            {selectedSlot && (
                <>
                    <TextField
                        label="Item Name"
                        value={itemName}
                        onChange={(e) => {
                            setItemName(e.target.value);
                            setItemNameError(false); // Reset error state when user starts typing
                        }}
                        fullWidth
                        margin="normal"
                        error={itemNameError} // Use error state to control the error display
                        helperText={itemNameError ? "Item name is required" : ""} // Display helper text when there's an error
                    />
                    {bonuses.map((bonus, index) => (
                        <Grid container spacing={2} key={index} alignItems="center" mb={2}>
                            <Grid item>
                                <Select value={bonus.type} onChange={handleBonusChange(index, 'type')} sx={{ minWidth: 150 }}>
                                    {getBonusTypes().map(type => <MenuItem key={type} value={type}>{type}</MenuItem>)}
                                </Select>
                            </Grid>
                            {bonus.id !== undefined && (
                                <Grid item>
                                    <Select value={bonus.id} onChange={handleBonusChange(index, 'id')} sx={{ minWidth: 150 }}>
                                        {getBonusIds(bonus.type).map(id => <MenuItem key={id} value={id}>{id}</MenuItem>)}
                                    </Select>
                                </Grid>
                            )}
                            <Grid item>
                                <TextField
                                    label="Value"
                                    type="number"
                                    value={bonus.value} // Ensure this is linked to your bonus.value
                                    onChange={handleBonusChange(index, 'value')}
                                />
                            </Grid>
                            <Grid item>
                                <Button onClick={() => removeBonus(index)}>Remove</Button>
                            </Grid>
                        </Grid>
                    ))}
                    <div style={{ margin: '20px 0' }}> {/* Add spacing */}
                        <Button onClick={addBonus} variant="outlined" sx={{ marginRight: 2 }}>Add Bonus</Button>
                        <Button variant="contained" color="primary" onClick={() => handleAddCustomGearToSlot(true)} sx={{ marginRight: 2 }}>Add Custom Item to {selectedSlot}</Button>
                        <Button variant="outlined" color="secondary" onClick={() => handleAddCustomGearToSlot(false)}>Add to Inventory</Button>                    </div>
                    {/*<Typography variant="h6">Custom Item Preview:</Typography>*/}
                    <pre>{JSON.stringify({ name: itemName, slot: selectedSlot, realm: template.realm, bonuses, tags: ["custom"] }, null, 2)}</pre>
                </>
            )}
        </div>
    );
};

export default CustomItem;
