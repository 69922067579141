import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Paper, Table, TableBody, TableCell, CircularProgress, TableContainer, TableHead, TableRow, TablePagination, TableSortLabel, Typography, TextField, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from "@mui/material/Button";
import { useTheme } from '@mui/material/styles';

const YourTemplates = ({ userTemplates, onEdit, onDelete, handleCreateTemplate, loading }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [searchTerm, setSearchTerm] = useState('');
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const handleDeleteClick = (templateId) => {
        onDelete && onDelete(templateId);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset page to zero when rows per page changes
    };

    const handleRequestSort = (property) => {
        const isAsc = sortField === property && sortOrder === 'asc';
        setSortField(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const filteredTemplates = searchTerm
        ? userTemplates.filter(template => template.name.toLowerCase().includes(searchTerm))
        : userTemplates;

    const sortedTemplates = [...filteredTemplates].sort((a, b) => {
        if (sortField === 'isPrivate') { // Special handling for boolean field
            return (a[sortField] === b[sortField]) ? 0 : (a[sortField] ? -1 : 1) * (sortOrder === 'asc' ? 1 : -1);
        }
        if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
        if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
        return 0;
    });

    return (
        <React.Fragment>
            <Box display="flex" justifyContent="flex-end" marginBottom={2}>
                <TextField label="Search" variant="outlined" onChange={handleSearchChange} sx={{ marginRight: 2 }} />
                <Button variant="contained" color="primary" onClick={handleCreateTemplate}>
                    Create New Template
                </Button>
            </Box>
            <TableContainer component={Paper}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'name'}
                                        direction={sortField === 'name' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('name')}
                                    >
                                        Name
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'realm'}
                                        direction={sortField === 'realm' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('realm')}
                                    >
                                        Realm
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'level'}
                                        direction={sortField === 'level' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('level')}
                                    >
                                        Level
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'class'}
                                        direction={sortField === 'class' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('class')}
                                    >
                                        Class
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'isPrivate'}
                                        direction={sortField === 'isPrivate' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('isPrivate')}
                                    >
                                        Private
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedTemplates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((template) => (
                                <TableRow key={template.id}>
                                    <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', color: primaryColor }}>
                                        <Link to={`/templates/${template.id}`} style={{ color: primaryColor }}>
                                            {template.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{template.realm}</TableCell>
                                    <TableCell>{template.level}</TableCell>
                                    <TableCell>{template.class}</TableCell>
                                    <TableCell>{template.isPrivate ? 'Private' : 'Public'}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteClick(template.id)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <TablePagination
                    rowsPerPageOptions={[10, 25, 50, 100]}
                    component="div"
                    count={userTemplates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </React.Fragment>
    );
};

export default YourTemplates;
