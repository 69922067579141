import React, {useState, useEffect, useMemo} from 'react';
import {
    Select, MenuItem, TextField, InputAdornment,
    IconButton, TableContainer, Table, TableHead, TableRow,
    TableCell, TableBody, TablePagination, Grid, Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CollapsibleRow from "./CollapsibleRow";
import classSlotWeaponMap from '../../data/items/classSlotWeaponMap.json';
import classArmorOptionsMap from '../../data/items/classArmorOptionsMap.json';
import customItemData from '../../data/customItemData.json';



function ItemList({ selectedSlot, selectedItem, template, addGearToSlot }) {
    const [page, setPage] = useState(0);
    const [gearData, setGearData] = useState(null); // Initialize gearData as null
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [weaponsList, setWeaponsList] = useState([]);
    const [selectedWeapon, setSelectedWeapon] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [sortKey, setSortKey] = useState('utility');
    const weaponSlots = useMemo(() => ['Main Hand', 'Off Hand', 'Two-Hand', 'Ranged'], []);
    const [selectedArmorType, setSelectedArmorType] = useState('');
    const armorSlots = ['Torso', 'Helm', 'Arms', 'Hands', 'Legs', 'Feet'];
    const accessorySlots = ['Necklace', 'Mythirian', 'Cloak', 'Jewel', 'Belt', 'Ring', 'Bracer'];
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        // Set the default armor type based on the class
        const classArmorOptions = classArmorOptionsMap[template.class] || { default: 'Cloth', options: ['Cloth'] };
        setSelectedArmorType(classArmorOptions.default);
    }, [template.class]);

    const generateJsonPath = (template, selectedSlot, selectedWeapon, weaponSlots) => {
        // Check if the selectedSlot is in one of the defined arrays
        if (armorSlots.includes(selectedSlot)) {
            return `${template.realm}/${selectedArmorType}/${selectedSlot}.json`;
        } else if (accessorySlots.includes(selectedSlot)) {
            return `${template.realm}/${selectedSlot}/${selectedSlot}.json`;
        } else if (weaponSlots.includes(selectedSlot)) {
            const [weaponName, subType] = selectedWeapon.split('-');
            const path = subType ? `${template.realm}/Weapons/${weaponName}/${subType}.json` : `${template.realm}/Weapons/${weaponName}/${weaponName}.json`;
            return path;
        } else {
            // Handle other slot-specific paths if needed
        }
    };

    // Add new filter
    const addFilter = () => {
        const firstType = Object.keys(customItemData[template.realm])[0];
        const firstId = customItemData[template.realm][firstType].length > 0
            ? customItemData[template.realm][firstType][0] : '';

        const newFilter = {
            type: firstType,
            operator: '>',
            value: '0',
            id: firstId
        };

        setFilters([...filters, newFilter]);
    };

    // Handle filter change
    const handleFilterChange = (index, field, value) => {
        const newFilters = [...filters];
        newFilters[index] = { ...newFilters[index], [field]: value };

        if (field === 'type') {
            const ids = customItemData[template.realm][value];
            newFilters[index].id = ids.length > 0 ? ids[0] : '';
        }

        setFilters(newFilters);
    };

    // Remove filter
    const removeFilter = (index) => {
        setFilters(filters.filter((_, i) => i !== index));
    };

    // Apply filters
    const applyFilters = (item) => {
        return filters.every(filter => {
            // Ensure item.bonuses exists and is an array before attempting to call find
            if (!Array.isArray(item.bonuses)) return false; // This line prevents the TypeError

            const itemBonus = item.bonuses.find(bonus =>
                bonus.type === filter.type &&
                (!customItemData[template.realm][filter.type].length || bonus.id === filter.id));
            if (!itemBonus) return false;

            const itemValue = parseInt(itemBonus.value, 10);
            const filterValue = parseInt(filter.value, 10);
            return itemValue >= filterValue; // Only 'greater than or equal to' condition
        });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleWeaponChange = (event) => {
        setSelectedWeapon(event.target.value);
    };

    const handleSelectItem = (item, setActive) => {
        if (selectedSlot) {
            addGearToSlot(selectedItem, item, setActive);
            setSearchQuery('');
        } else {
            // Optionally, provide feedback to the user that they need to select a slot first
            console.log("Please select a slot first.");
        }
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSortChange = (event) => {
        setSortKey(event.target.value);
    };


    useEffect(() => {
        if (weaponSlots.includes(selectedSlot)) {
            // Filtering weapons based on class permissions
            const allowedWeaponsInSlot = classSlotWeaponMap[template.realm][template.class][selectedSlot] || [];
            const filteredWeapons = allowedWeaponsInSlot.map(weapon => {
                if (typeof weapon === 'string') {
                    return weapon;
                } else {
                    return weapon.subTypes.map(subType => `${weapon.name}-${subType}`);
                }
            }).flat();
            setWeaponsList(filteredWeapons);
            setSelectedWeapon(filteredWeapons.length > 0 ? filteredWeapons[0] : '');
        } else {
            setWeaponsList([]);
            setSelectedWeapon('');
        }
    }, [selectedSlot, template.realm, template.class, weaponSlots]);

// Fetch gear data based on selectedSlot and selectedWeapon
    useEffect(() => {
        setPage(0);
        if (!selectedSlot || selectedSlot === '') {
            setGearData(null); // Clear gear data when no slot is selected
            return;
        }
        if (selectedSlot && selectedSlot !== '') {
            if (weaponSlots.includes(selectedSlot) && selectedWeapon === '') {
                // Skip fetching if it's a weapon slot but no weapon is selected
                return;
            }
            const dynamicPath = generateJsonPath(template, selectedSlot, selectedWeapon, weaponSlots, selectedArmorType);
            import(`../../data/items/${dynamicPath}`)
                .then((module) => {
                    const data = module.default;
                    setGearData(data);
                })
                .catch((error) => {
                    console.error('Error fetching gear data:', error.message);
                });
        }
    }, [selectedSlot, selectedWeapon, selectedArmorType, template, weaponSlots]);

    const filteredData = gearData ? gearData
        .filter(item => {
            const matchesSearch = item.name.toLowerCase().includes(searchQuery.toLowerCase());
            const hasNoRequirements = !item.requirements || !item.requirements.usable_by;
            const meetsRequirements = hasNoRequirements || item.requirements.usable_by.includes(template.class);

            let slotMatch = true;
            if (selectedSlot === 'Off Hand') {
                // Include if it's left handed or if it's a shield
                slotMatch = item.type_data?.left_handed === 1 || item.category === 'Shield';
            } else if (selectedSlot === 'Two-Hand') {
                // Include if it's two handed
                slotMatch = item.type_data?.two_handed === 1 || item.category === 'Instrument';
            }

            return matchesSearch && meetsRequirements && slotMatch && applyFilters(item);
        })
        .sort((a, b) => {
            if (sortKey === 'name') {
                return a.name.localeCompare(b.name);
            } else if (sortKey === 'utility') {
                return b.utility - a.utility; // Assuming 'utility' is a number
            }
            return 0;
        }) : [];

    let content;
    if (!filteredData || filteredData.length === 0) {
        content = <TableRow><TableCell colSpan={5}>No data available</TableCell></TableRow>;
    } else {
        const startIndex = page * rowsPerPage;
        const endIndex = startIndex + rowsPerPage;
        content = filteredData.slice(startIndex, endIndex).map((item) => (
            <CollapsibleRow key={item.id} item={item} onSelectItem={handleSelectItem} />
        ));
    }

    function getArmorDisplayName(armorType, realm) {
        if (realm === "Hibernia") {
            return armorType === "Studded" ? "Reinforced" : armorType === "Chain" ? "Scale" : armorType;
        }
        return armorType;
    }


    return (
        <div>
            {/*<Typography variant="h6">Item List</Typography>*/}
            <Grid container spaceing={2}>
                <Grid item xs={12} sm={12} md={12}>
                    {weaponSlots.includes(selectedSlot) && (
                        <Select
                            sx={{ minWidth: 230}}
                            value={selectedWeapon}
                            onChange={handleWeaponChange}
                            style={{ margin: '10px' }}
                        >
                            {weaponsList.map(weapon =>
                                typeof weapon === 'string' ? (
                                    <MenuItem key={weapon} value={weapon}>{weapon}</MenuItem>
                                ) : (
                                    weapon.subTypes.map(subType => (
                                        <MenuItem key={`${weapon.name}-${subType}`} value={`${weapon.name}-${subType}`}>
                                            {`${weapon.name} (${subType})`}
                                        </MenuItem>
                                    ))
                                )
                            )}
                        </Select>
                    )}
                    {armorSlots.includes(selectedSlot) && (
                        <Select
                            value={selectedArmorType}
                            onChange={(e) => setSelectedArmorType(e.target.value)}
                            style={{ margin: '10px' }}
                        >
                            {classArmorOptionsMap[template.class].options.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {getArmorDisplayName(type, template.realm)}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                    <TextField
                        label="Search Item"
                        variant="outlined"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        style={{ margin: '10px' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {searchQuery && (
                                        <IconButton
                                            onClick={() => setSearchQuery('')}
                                            edge="end"
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Select
                        value={sortKey}
                        onChange={handleSortChange}
                        style={{ margin: '10px' }}
                    >
                        <MenuItem value="name">Sort by Name</MenuItem>
                        <MenuItem value="utility">Sort by Utility</MenuItem>
                    </Select>
                </Grid>

            {filters.map((filter, index) => (
                <Grid item key={index} xs={12} sm={12} md={12}>
                        <Select
                            value={filter.type}
                            onChange={(e) => handleFilterChange(index, 'type', e.target.value)}
                            sx={{ minWidth: 150 }}
                            style={{ margin: '10px' }}
                        >
                            {Object.keys(customItemData[template.realm]).map(type => (
                                <MenuItem key={type} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    {customItemData[template.realm][filter.type] && customItemData[template.realm][filter.type].length > 0 && (
                            <Select
                                value={filter.id || ''}
                                onChange={(e) => handleFilterChange(index, 'id', e.target.value)}
                                sx={{ minWidth: 150 }}
                                style={{ margin: '10px' }}
                            >
                                {customItemData[template.realm][filter.type].map(id => (
                                    <MenuItem key={id} value={id}>{id}</MenuItem>
                                ))}
                            </Select>
                    )}
                        <TextField
                            type="number"
                            value={filter.value}
                            onChange={(e) => handleFilterChange(index, 'value', e.target.value)}
                            style={{ margin: '10px' }}
                        />
                        <Button onClick={() => removeFilter(index)}>Remove</Button>
                </Grid>
            ))}
            <Button onClick={addFilter} variant="outlined" sx={{ margin: '10px' }}>Add Filter</Button>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Utility</TableCell>
                            {/*<TableCell>Tags</TableCell>*/}
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{content}</TableBody>
                </Table>
                <TablePagination
                    component="div"
                    count={gearData ? gearData.length : 0}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            </Grid>
        </div>
    );
}

export default ItemList;
