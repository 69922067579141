import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../hooks/useAuthContext';
import { db } from '../../firebase/config';
import {
    collection,
    addDoc,
    doc,
    deleteDoc,
    query, getDocs, where,
} from 'firebase/firestore';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import YourTemplates from "../../components/templates/YourTemplates";
import {Container} from "@mui/material";


const realmToClasses = {
    Albion: [
        'Armsman', 'Cabalist', 'Cleric', 'Friar', 'Heretic', 'Infiltrator', 'Mauler', 'Mercenary',
        'Minstrel', 'Necromancer', 'Paladin', 'Reaver', 'Scout', 'Sorcerer', 'Theurgist', 'Wizard'
    ],
    Midgard: [
        'Berserker', 'Bonedancer', 'Healer', 'Hunter', 'Mauler', 'Runemaster', 'Savage', 'Shadowblade',
        'Shaman', 'Skald', 'Spiritmaster', 'Thane', 'Valkyrie', 'Warlock', 'Warrior'
    ],
    Hibernia: [
        'Animist', 'Bainshee', 'Bard', 'Blademaster', 'Champion', 'Druid', 'Eldritch', 'Enchanter', 'Hero',
        'Mauler', 'Mentalist', 'Nightshade', 'Ranger', 'Valewalker', 'Vampiir', 'Warden'
    ],
};

const validLevels = [50];

const Templates = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const isOnline = () => navigator.onLine;
    const [userTemplates, setUserTemplates] = useState([]);
    const [templateToDelete, setTemplateToDelete] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [createTemplateDialogOpen, setCreateTemplateDialogOpen] = useState(false);
    const [newTemplateInfo, setNewTemplateInfo] = useState({
        name: '',
        realm: Object.keys(realmToClasses)[0], // Set the default realm
        class: realmToClasses[Object.keys(realmToClasses)[0]][0], // Set the default class
        level: validLevels[0], // Set the default level
    });
    const [classOptions, setClassOptions] = useState(realmToClasses[Object.keys(realmToClasses)[0]]); // Set default class options
    const [validationErrors, setValidationErrors] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [loading, setLoading] = useState(false);



    const fetchTemplates = async (retryCount = 3, delay = 2000) => {
        if (!isOnline()) {
            setSnackbarMessage('No network connection. Retrying...');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }
        try {
            setLoading(true);
            // Query templates collection where owner is the current user
            const q = query(collection(db, 'templates'), where('owner', '==', user.uid));
            const querySnapshot = await getDocs(q);
            const fetchedTemplates = [];
            querySnapshot.forEach((doc) => {
                fetchedTemplates.push({ id: doc.id, ...doc.data() });
            });
            setUserTemplates(fetchedTemplates);
            setSnackbarOpen(false);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching templates:', error);
            if (retryCount > 0) {
                setSnackbarMessage(`Error fetching data. Retrying in ${delay / 1000} seconds...`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setTimeout(() => fetchTemplates(retryCount - 1, delay), delay);
            } else {
                setSnackbarMessage('Failed to fetch data. Please check your connection and try again.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!user) return;
        fetchTemplates();
    }, [user]);

    const handleCreateTemplate = () => {
        setCreateTemplateDialogOpen(true);
    };

    const createNewTemplate = async () => {
        try {
            if (!user) {
                console.error('User not authenticated');
                return;
            }


            // Validate the new template info
            const errors = {};
            if (!newTemplateInfo.name.trim()) {
                errors.name = 'Name is required';
            } else if (newTemplateInfo.name.length > 75) {
                errors.name = 'Name must be less than 75 characters';
            }

            if (Object.keys(errors).length > 0) {
                setValidationErrors(errors);
                return;
            }

            const currentDate = new Date();

            const templatesCollectionRef = collection(db, `templates`);
            const newTemplateRef = await addDoc(templatesCollectionRef, {
                owner: user.uid,
                ownerName: user.displayName,
                ...newTemplateInfo,
                isPrivate: true,
                createdOn: currentDate,
                lastUpdated: currentDate,
            });

            console.log('New template created');
            navigate(`/templates/${newTemplateRef.id}`);
        } catch (error) {
            console.error('Error creating template:', error);
        } finally {
            setCreateTemplateDialogOpen(false);
        }
    };

    const handleEditClick = (templateId) => {
        navigate(`/templates/${templateId}`);

    };


    const handleDeleteClick = (templateId) => {
        console.log('Delete Clicked', { templateId });
        setTemplateToDelete({ id: templateId });
    };

    useEffect(() => {
        if (templateToDelete !== null) {
            setDialogOpen(true);
        }
    }, [templateToDelete]);

    const handleConfirmDelete = async ({ id: templateId }) => {
        console.log('Confirm Delete', { templateId });
        if (templateId) {
            try {
                // Delete the template document from the new location
                const templateDocRef = doc(db, `templates`, templateId);
                await deleteDoc(templateDocRef);

                // Update state
                setUserTemplates(prevTemplates => prevTemplates.filter(template => template.id !== templateId));
            } catch (error) {
                console.error('Error deleting template:', error);
            } finally {
                // Ensure dialog is closed after operation is complete
                setDialogOpen(false);
            }
        }
    };



    const handleDialogClose = () => {
        setTemplateToDelete(null);
        setDialogOpen(false);
    };

    const handleCreateTemplateDialogClose = () => {
        setCreateTemplateDialogOpen(false);
        setValidationErrors({}); // Clear validation errors
    };

    const handleNewTemplateInfoChange = (e) => {
        const { name, value } = e.target;

        if (name === 'realm') {
            // Update class options based on the selected realm
            setNewTemplateInfo((prevInfo) => ({
                ...prevInfo,
                [name]: value,
                class: realmToClasses[value][0],
            }));
            setClassOptions(realmToClasses[value]);
        } else {
            setNewTemplateInfo((prevInfo) => ({
                ...prevInfo,
                [name]: name === 'level' ? parseInt(value) : value,
            }));
        }
    };

    return (
        <Container >
            <YourTemplates
                userTemplates={userTemplates}
                onEdit={handleEditClick}
                onDelete={handleDeleteClick}
                handleCreateTemplate={handleCreateTemplate}
                loading={loading}
            />
            <Dialog open={dialogOpen} onClose={handleDialogClose}>
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    Are you sure you want to delete this template and all associated data?
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button
                            onClick={() => handleConfirmDelete(templateToDelete)}
                            variant="contained"
                            color="secondary"
                        >
                            Yes
                        </Button>
                        <Button onClick={handleDialogClose} variant="contained" color="primary">
                            No
                        </Button>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

            {/* Create New Template Dialog */}
            <Dialog open={createTemplateDialogOpen} onClose={handleCreateTemplateDialogClose}>
                <DialogTitle>Create New Template</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                label="Name"
                                name="name"
                                value={newTemplateInfo.name}
                                onChange={handleNewTemplateInfoChange}
                                fullWidth
                                error={Boolean(validationErrors.name)}
                                helperText={validationErrors.name}
                                inputProps={{ maxLength: 75 }} // Limit input length to 100 characters
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Realm</InputLabel>
                                <Select
                                    label="Realm"
                                    name="realm"
                                    value={newTemplateInfo.realm}
                                    onChange={handleNewTemplateInfoChange}
                                >
                                    {Object.keys(realmToClasses).map((realm) => (
                                        <MenuItem key={realm} value={realm}>
                                            {realm}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Class</InputLabel>
                                <Select
                                    label="Class"
                                    name="class"
                                    value={newTemplateInfo.class}
                                    onChange={handleNewTemplateInfoChange}
                                >
                                    {classOptions.map((className) => (
                                        <MenuItem key={className} value={className}>
                                            {className}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Level</InputLabel>
                                <Select
                                    label="Level"
                                    name="level"
                                    value={newTemplateInfo.level}
                                    onChange={handleNewTemplateInfoChange}
                                >
                                    {validLevels.map((level) => (
                                        <MenuItem key={level} value={level}>
                                            {level}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCreateTemplateDialogClose} variant="contained" color="primary">
                        Cancel
                    </Button>
                    <Button onClick={createNewTemplate} variant="contained" color="primary">
                        Create
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Templates;
