import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, Select, MenuItem, FormControl, InputLabel, Box, Tabs, Tab, Button } from '@mui/material';
import Stats from "../../components/planner/Stats";
import Skills from "../../components/planner/Skills";
import RealmAbilities from "../../components/planner/RealmAbilities";
import MasterLevels from "../../components/planner/MasterLevels";
import ChampionLevels from "../../components/planner/ChampionLevels";

const realmToClasses = {
    Albion: ['Armsman', 'Cabalist', 'Cleric', 'Friar', 'Heretic', 'Infiltrator', 'Mauler', 'Mercenary', 'Minstrel', 'Necromancer', 'Paladin', 'Reaver', 'Scout', 'Sorcerer', 'Theurgist', 'Wizard'],
    Midgard: ['Berserker', 'Bonedancer', 'Healer', 'Hunter', 'Mauler', 'Runemaster', 'Savage', 'Shadowblade', 'Shaman', 'Skald', 'Spiritmaster', 'Thane', 'Valkyrie', 'Warlock', 'Warrior'],
    Hibernia: ['Animist', 'Bainshee', 'Bard', 'Blademaster', 'Champion', 'Druid', 'Eldritch', 'Enchanter', 'Hero', 'Mauler', 'Mentalist', 'Nightshade', 'Ranger', 'Valewalker', 'Vampiir', 'Warden'],
};

const CharacterPlanner = () => {
    const [characterData, setCharacterData] = useState({
        realm: '',
        className: '',
        race: '',
        level: 50,
        realmRank: '1L0',
        realmLevel: 0,
        championLevel: 1,
        baseStats: {},
        skills: {},
    });

    const [races, setRaces] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchRaces = useCallback(async (realm, className) => {
        setLoading(true);
        try {
            const classData = await import(`../../data/classes/${realm}/${className}.json`);
            setRaces(classData.races || []);
        } catch (error) {
            console.error("Error loading class data:", error);
            setRaces([]);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (characterData.realm && characterData.className) {
            fetchRaces(characterData.realm, characterData.className);
        }
    }, [characterData.realm, characterData.className]);

    const updateCharacterData = (updatedFields) => {
        setCharacterData((prevData) => {
            const updates = typeof updatedFields === 'function' ? updatedFields(prevData) : updatedFields;
            const newCharacterData = {
                ...prevData,
                ...updates,
            };
            console.log("Updated Character Data:", JSON.stringify(newCharacterData, null, 2));
            return newCharacterData;
        });
    };

    const handleRealmChange = (event) => {
        const selectedRealm = event.target.value;

        updateCharacterData({
            realm: selectedRealm,
            className: '',
            race: '',
            baseStats: {},
            skills: {},
        });
        setRaces([]); // Reset races when realm changes
    };

    const handleClassChange = (event) => {
        const selectedClass = event.target.value;

        updateCharacterData({
            className: selectedClass,
            race: '',
            baseStats: {},
            skills: {},
        });
    };

    const handleTabChange = (event, newValue) => {
        if (!characterData.realm || !characterData.className) {
            alert("Please select a Realm and Class first.");
            return;
        }
        setSelectedTab(newValue);
    };

    // Function to generate level options, including half-levels from 40 to 50
    const generateLevelOptions = () => {
        const levels = [];
        for (let i = 1; i <= 39; i++) {
            levels.push(i);
        }
        for (let i = 40; i <= 50; i++) {
            levels.push(i);
            if (i < 50) {
                levels.push(i + 0.5);
            }
        }
        return levels;
    };

    return (
        <Box>
            <AppBar position="static">
                <Toolbar>
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>Realm</InputLabel>
                        <Select value={characterData.realm} onChange={handleRealmChange} label="Realm">
                            {Object.keys(realmToClasses).map((realm) => (
                                <MenuItem key={realm} value={realm}>{realm}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" disabled={!characterData.realm}>
                        <InputLabel>Class</InputLabel>
                        <Select value={characterData.className} onChange={handleClassChange} label="Class">
                            {realmToClasses[characterData.realm]?.map((className) => (
                                <MenuItem key={className} value={className}>{className}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small" disabled={!characterData.className || loading}>
                        <InputLabel>Race</InputLabel>
                        <Select value={characterData.race} onChange={(e) => updateCharacterData({ race: e.target.value })} label="Race">
                            {loading ? (
                                <MenuItem disabled>Loading...</MenuItem>
                            ) : (
                                races.map((race) => (
                                    <MenuItem key={race} value={race}>{race}</MenuItem>
                                ))
                            )}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>LVL</InputLabel>
                        <Select value={characterData.level} onChange={(e) => updateCharacterData({ level: e.target.value })} label="LVL">
                            {generateLevelOptions().map((level) => (
                                <MenuItem key={level} value={level}>{level}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>RR</InputLabel>
                        <Select value={parseInt(characterData.realmRank)} onChange={(e) => updateCharacterData({ realmRank: `${e.target.value}L${characterData.realmLevel}` })} label="RR">
                            {[...Array(14).keys()].map((i) => (
                                <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>RL</InputLabel>
                        <Select value={characterData.realmLevel} onChange={(e) => updateCharacterData({ realmLevel: e.target.value, realmRank: `${parseInt(characterData.realmRank)}L${e.target.value}` })} label="RL">
                            {[...Array(10).keys()].map((i) => (
                                <MenuItem key={i} value={i}>{i}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                        <InputLabel>CL</InputLabel>
                        <Select value={characterData.championLevel} onChange={(e) => updateCharacterData({ championLevel: e.target.value })} label="CL">
                            {[...Array(15).keys()].map((i) => (
                                <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <Button variant="contained" color="primary" onClick={() => console.log(characterData)} sx={{ ml: 2 }}>
                        Save
                    </Button>
                </Toolbar>
            </AppBar>

            <Tabs value={selectedTab} onChange={handleTabChange} centered>
                <Tab label="Stats" />
                <Tab label="Skills" />
                <Tab label="Realm Abilities" />
                <Tab label="Master Levels" />
                <Tab label="Champion Levels" />
            </Tabs>

            <Box p={3}>
                {selectedTab === 0 && characterData.race && (
                    <Stats characterData={characterData} updateCharacterData={updateCharacterData} />
                )}
                {selectedTab === 1 && (
                    <Skills characterData={characterData} updateCharacterData={updateCharacterData} />
                )}
                {selectedTab === 2 && <RealmAbilities />}
                {selectedTab === 3 && <MasterLevels />}
                {selectedTab === 4 && <ChampionLevels />}
            </Box>
        </Box>
    );
};

export default CharacterPlanner;
