import React from 'react';
import { Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';

const TemplateResistancesNew = ({ statistics }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const resistanceKeys = ['Crush', 'Slash', 'Thrust', 'Heat', 'Cold', 'Matter', 'Body', 'Spirit', 'Energy'];

    const rowStyle = {
        padding: '2px 0',
        transition: 'background-color 0.3s',
    };

    const cellStyle = {
        borderBottom: '1px solid',
        borderTop: '1px solid',
        padding: '2px 8px',
        fontSize: '0.8rem',
        color: primaryColor,
    };

    const renderRow = (key) => {
        if (!statistics[key]) return null;
        const { value, mythicalCap } = statistics[key];
        const baseCap = 26; // Base cap for resistances
        const totalCap = baseCap + mythicalCap;

        const differenceToMaxItem = value - totalCap;

        const colorItem = differenceToMaxItem >= 0 ? 'green' : 'red';

        return (
            <TableRow
                key={key}
                style={rowStyle}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme.palette.action.hover}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            >
                <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }}>{key}</TableCell>
                <TableCell style={cellStyle}>{value}</TableCell>
                <TableCell style={cellStyle}><span style={{ color: colorItem }}>{differenceToMaxItem}</span></TableCell>
                <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>{totalCap}</TableCell>
                <TableCell style={cellStyle}>{baseCap}</TableCell>
                <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>{mythicalCap}</TableCell>
            </TableRow>
        );
    };

    return (
        <Paper style={{ padding: '8px', flex: 1, height: '100%' }}>
            <Typography variant="h6" style={{ fontSize: '1rem', marginBottom: '8px' }}>Resistances</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }}>Resistance</TableCell>
                        <TableCell style={cellStyle}>Value</TableCell>
                        <TableCell style={cellStyle}>+/-</TableCell>
                        <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>Total Cap</TableCell>
                        <TableCell colSpan={2} style={{ ...cellStyle, textAlign: 'center', borderLeft: '1px solid', borderRight: '1px solid' }}>Caps</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }} />
                        <TableCell style={cellStyle} />
                        <TableCell style={cellStyle} />
                        <TableCell style={{ ...cellStyle, borderRight: '1px solid' }} />
                        <TableCell style={cellStyle}>Base</TableCell>
                        <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>Mythical</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {resistanceKeys.map(renderRow)}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default TemplateResistancesNew;
