import React, { useState, useEffect } from 'react';
import {
    Button,
    TextField,
    Snackbar,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Select,
    MenuItem
} from '@mui/material';
import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential, deleteUser } from 'firebase/auth';
import { db } from '../../firebase/config';
import {collection, deleteDoc, doc, getDocs, query, where} from "firebase/firestore";
import { useLogout } from '../../hooks/useLogout';
import Typography from "@mui/material/Typography";

const Settings = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [passwordForDeletion, setPasswordForDeletion] = useState('');
    const [viewPreference, setViewPreference] = useState('Table view');
    const auth = getAuth();
    const user = auth.currentUser;
    const { logout } = useLogout();

    useEffect(() => {
        const storedPreference = localStorage.getItem('viewPreference');
        if (storedPreference) {
            setViewPreference(storedPreference);
        }
    }, []);

    const handleViewPreferenceChange = (event) => {
        const preference = event.target.value;
        setViewPreference(preference);
        localStorage.setItem('viewPreference', preference);
    };


    const handleChangePassword = async () => {
        if (newPassword !== confirmPassword) {
            setSnackbarMessage('Passwords do not match');
            setOpenSnackbar(true);
            return;
        }

        // Re-authenticate the user
        const credential = EmailAuthProvider.credential(
            user.email,
            currentPassword
        );

        try {
            await reauthenticateWithCredential(user, credential);
            await updatePassword(user, newPassword);
            setSnackbarMessage('Password updated successfully');
        } catch (error) {
            // Custom message for incorrect password
            if (error.code === 'auth/wrong-password' || error.code === 'auth/invalid-credential') {
                setSnackbarMessage("The password you provided is incorrect.");
            } else {
                setSnackbarMessage('Error updating password: ' + error.message);
            }
        }
        setOpenSnackbar(true);
    };

    const handleOpenConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleCloseConfirmDialog = () => {
        setOpenConfirmDialog(false);
    };

    const handleConfirmDelete = async () => {
        handleCloseConfirmDialog();
        const credential = EmailAuthProvider.credential(user.email, passwordForDeletion);
        try {
            await reauthenticateWithCredential(user, credential);
            // Query and delete user's templates
            const templatesQuery = query(collection(db, 'templates'), where('owner', '==', user.uid));
            const querySnapshot = await getDocs(templatesQuery);
            querySnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
            });
            // Delete user data from Firestore
            const userDocRef = doc(db, 'users', user.uid);
            await deleteDoc(userDocRef);

            // Delete the user account
            await deleteUser(user);

            // Set confirmation message and log out
            setSnackbarMessage('Your account and all associated data have been successfully deleted.');
            setOpenSnackbar(true);
            setTimeout(() => logout(), 3000); // Logout after a brief pause for the user to read the message
        } catch (error) {
            setSnackbarMessage('Error deleting account: ' + error.message);
            setOpenSnackbar(true);
        }
    };


    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6}>
                <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                <Typography variant="h6">Change Password</Typography>
                <TextField
                    label="Current Password"
                    type="password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="New Password"
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Confirm New Password"
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <Button onClick={handleChangePassword} variant="contained" color="primary" fullWidth>
                    Change Password
                </Button>
                </Paper>
            </Grid>

            <Grid item xs={12} md={6}>
                <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                    <Typography variant="h6">Edit Template Display Options</Typography>
                    <Select
                        value={viewPreference}
                        onChange={handleViewPreferenceChange}
                        fullWidth
                        margin="normal"
                    >
                        <MenuItem value="Table view">Table view</MenuItem>
                        <MenuItem value="Simple view">Simple view</MenuItem>
                    </Select>
                </Paper>
            </Grid>

            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                message={snackbarMessage}
            />

            <Grid item xs={12} md={6}>
                <Paper style={{ padding: '16px' }}>

                <Typography variant="h6">Delete Account</Typography>
                <Typography variant="body1" gutterBottom mt={2}>
                    I feel that you should own your account and data, and if you would like to remove any of those things, you should be able to. By using the Delete Account button, it will remove your user data, templates, and character data from the database. This action is not reversible as I will not store your data that you have asked to be deleted.
                </Typography>
                <Button onClick={handleOpenConfirmDialog} variant="contained" color="warning">
                    Delete Account
                </Button>
                </Paper>
            </Grid>
            {/* Confirmation Dialog */}
            <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
                <DialogTitle>Confirm Account Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText mb={2}>
                        Please enter your current password to confirm the deletion of your account. This action cannot be undone.
                    </DialogContentText>
                    <TextField
                        label="Current Password"
                        type="password"
                        fullWidth
                        onChange={(e) => setPasswordForDeletion(e.target.value)}
                        autoFocus
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseConfirmDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

export default Settings;
