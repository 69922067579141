import React, { useEffect } from 'react';
import { useAuthContext } from '../../hooks/useAuthContext';
import {Button, Container} from '@mui/material';

const Home = () => {
    const { user } = useAuthContext();

    useEffect(() => {
        // You can perform other user-related fetches or operations here if needed
    }, [user]); // Dependency on user ensures this runs when the user state changes

    return (
        <Container>
            <h1>Welcome to DAoC Buddy!</h1>
            <p>
                {user ? (
                    `Welcome, ${user.displayName || 'User'}!`
                ) : (
                    'Please log in or sign up to join our community.'
                )}

            </p>
            {!user &&
                <Button
                    variant="contained"
                    color="primary"
                    href="https://daocbuddy.com/login"
                    target="_blank"
                >
                    Login
                </Button>
            }
            <p>
                DAoC Buddy is currently in "Open Beta" under active development. We're working hard to bring you features like a character planner, an extensive item database, and enhanced search functionality. Our goal is to build a strong and collaborative community around this project.
            </p>
            <p>
                We invite you to join our Discord community to discuss the project, submit feature requests, report bugs, and help us refine our item database. Your feedback and involvement are crucial in shaping DAoC Buddy into a valuable tool for all players.
            </p>
            <Button
                variant="contained"
                color="primary"
                href="https://discord.gg/ZUSbKeX2aQ"
                target="_blank"
            >
                Join DAoC Buddy on Discord
            </Button>
            <p>
                I've made some changes recently to make sure we keep our hosting and usage to minimum that should keep the project free for all users but of course, if you would like to make a donation I won't say no.
            </p>
            <p>
                Thank you! - Kevin (Axtel)
            </p>
            <Button
                variant="contained"
                color="primary"
                href="https://www.paypal.com/donate/?hosted_button_id=DE3XXF36TAHHL"
                target="_blank"
            >
                Donate to Support the Project
            </Button>
        </Container>
    );
};

export default Home;
