import React, { useState } from 'react';
import {
    Paper,
    Tabs,
    Tab
} from '@mui/material';
import ItemList from "./GearList/ItemList";
import Spellcrafting from "./GearList/Spellcrafting";
import CustomItem from "./GearList/CustomItem";

const GearList = ({ selectedSlot, selectedItem, addGearToSlot, template, gearBySlot, renderItemDetails }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <React.Fragment>
        <Paper style={{ padding: '16px' }}>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
                <Tab label="Item List" />
                <Tab label="Spellcrafting" />
                <Tab label="Custom Item" />
            </Tabs>
            {activeTab === 0 && <ItemList selectedSlot={selectedSlot} selectedItem={selectedItem} template={template} addGearToSlot={addGearToSlot} />}
            {activeTab === 1 && <Spellcrafting template={template} selectedSlot={selectedSlot} addGearToSlot={addGearToSlot} gearBySlot={gearBySlot} />}
            {activeTab === 2 && <CustomItem template={template} selectedSlot={selectedSlot} selectedItem={selectedItem} addGearToSlot={addGearToSlot} gearBySlot={gearBySlot} />}
        </Paper>
        </React.Fragment>
    );

};


export default GearList;
