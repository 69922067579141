const baseStats = {
    "Avalonian": {"Strength": 45, "Constitution": 45, "Dexterity": 60, "Quickness": 70, "Intelligence": 80, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Briton": {"Strength": 60, "Constitution": 60, "Dexterity": 60, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Half Ogre": {"Strength": 90, "Constitution": 70, "Dexterity": 40, "Quickness": 40, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Highlander": {"Strength": 70, "Constitution": 70, "Dexterity": 50, "Quickness": 50, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Inconnu": {"Strength": 50, "Constitution": 60, "Dexterity": 70, "Quickness": 50, "Intelligence": 70, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Minotaur": {"Strength": 80, "Constitution": 70, "Dexterity": 50, "Quickness": 40, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Saracen": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Kobold": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Dwarf": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Norseman": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Troll": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Valkyn": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Frostalf": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Celt": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Lurikeen": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Firbolg": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Elf": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Sylvan": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60},
    "Shar": {"Strength": 50, "Constitution": 50, "Dexterity": 80, "Quickness": 60, "Intelligence": 60, "Piety": 60, "Empathy": 60, "Charisma": 60}
};

export default baseStats;
