import React, { useState, useEffect } from 'react';
import {Link} from 'react-router-dom';
import {
    Paper, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, TablePagination, TextField,
    Select, MenuItem, InputLabel, FormControl, Button, CircularProgress, TableSortLabel, Box
} from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from "firebase/firestore";
import { useTheme } from '@mui/material/styles';

const PublicTemplates = ({ realm, class: selectedClass }) => {
    const [allTemplates, setAllTemplates] = useState([]);
    const [displayTemplates, setDisplayTemplates] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [selectedOwner, setSelectedOwner] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState('all');
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState('name');
    const [sortOrder, setSortOrder] = useState('asc');
    const [error, setError] = useState(null);
    const [retryCount, setRetryCount] = useState(0);
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const dateRanges = [
        { label: 'Last 7 Days', value: 7 },
        { label: 'Last 30 Days', value: 30 },
        { label: 'Last 90 Days', value: 90 },
        { label: 'All Time', value: 'all' }
    ];

    const fetchTemplates = async () => {
        setLoading(true);
        try {
            let q = query(collection(db, 'templates'), where('isPrivate', '==', false));

            if (realm) {
                q = query(q, where('realm', '==', realm));
            }

            if (selectedClass) {
                q = query(q, where('class', '==', selectedClass));
            }

            const querySnapshot = await getDocs(q);
            const fetchedTemplates = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setAllTemplates(fetchedTemplates);
            setDisplayTemplates(fetchedTemplates); // Initially, all templates are displayed
        } catch (error) {
            console.error("Error fetching templates: ", error);
            setError(error);
            if (error.code === 'unavailable' && retryCount < 3) {
                setTimeout(() => {
                    setRetryCount(retryCount + 1);
                    fetchTemplates();
                }, 2000 * retryCount); // exponential backoff
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchTemplates();
    }, [realm, selectedClass, retryCount]);

    useEffect(() => {
        const filtered = allTemplates.filter(template => {
            const createdDate = template.createdOn.toDate();
            const currentDate = new Date();
            const daysAgo = Math.floor((currentDate - createdDate) / (1000 * 3600 * 24));

            return (!selectedOwner || (template.ownerName && template.ownerName.toLowerCase().includes(selectedOwner.toLowerCase()))) &&
                (selectedDateRange === 'all' || daysAgo <= selectedDateRange);
        });
        setDisplayTemplates(filtered);
    }, [selectedOwner, selectedDateRange, allTemplates]);

    useEffect(() => {
        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setDisplayTemplates(allTemplates.slice(start, end));
    }, [page, rowsPerPage, allTemplates]);

    const handleRequestSort = (property) => {
        const isAsc = sortField === property && sortOrder === 'asc';
        setSortField(property);
        setSortOrder(isAsc ? 'desc' : 'asc');
    };

    const createSortHandler = (property) => (event) => {
        handleRequestSort(property);
    };

    useEffect(() => {
        const sortedTemplates = [...allTemplates].sort((a, b) => {
            let valueA, valueB;
            if (sortField === 'createdOn') {
                valueA = a.createdOn.toDate().getTime();
                valueB = b.createdOn.toDate().getTime();
            } else if (sortField === 'likes') {
                valueA = a.likes ? a.likes.length : 0;
                valueB = b.likes ? b.likes.length : 0;
            } else {
                valueA = a[sortField];
                valueB = b[sortField];
            }

            if (valueA < valueB) {
                return sortOrder === 'asc' ? -1 : 1;
            } else if (valueA > valueB) {
                return sortOrder === 'asc' ? 1 : -1;
            } else {
                return 0;
            }
        });

        const start = page * rowsPerPage;
        const end = start + rowsPerPage;
        setDisplayTemplates(sortedTemplates.slice(start, end));
    }, [page, rowsPerPage, allTemplates, sortField, sortOrder]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleResetFilters = () => {
        setSelectedOwner('');
        setSelectedDateRange('all');
        setPage(0);
    };

    const daysAgo = (createdOn) => {
        const createdDate = createdOn.toDate();
        const currentDate = new Date();
        const differenceInTime = currentDate - createdDate;
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
        return `${differenceInDays} days ago`;
    };

    return (
        <React.Fragment>
            <Box display="flex" justifyContent="flex-end" gap="10px" mb="20px">
                <FormControl style={{ minWidth: 120 }}>
                    <InputLabel>Date Range</InputLabel>
                    <Select
                        value={selectedDateRange}
                        onChange={(e) => setSelectedDateRange(e.target.value)}
                        label="Date Range"
                    >
                        {dateRanges.map((range, index) => (
                            <MenuItem key={index} value={range.value}>{range.label}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Created By"
                    value={selectedOwner}
                    onChange={(e) => setSelectedOwner(e.target.value)}
                />
                <Button variant="contained" color="primary" onClick={handleResetFilters}>
                    Reset Filters
                </Button>
            </Box>

            <TableContainer component={Paper}>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight={200}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel active={sortField === 'name'} direction={sortField === 'name' ? sortOrder : 'asc'} onClick={createSortHandler('name')}>Name</TableSortLabel>
                                </TableCell>
                                <TableCell>Level</TableCell>
                                <TableCell>
                                    <TableSortLabel active={sortField === 'ownerName'} direction={sortField === 'ownerName' ? sortOrder : 'asc'} onClick={createSortHandler('ownerName')}>Created By</TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'createdOn'}
                                        direction={sortField === 'createdOn' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('createdOn')}>
                                        Created
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortField === 'likes'}
                                        direction={sortField === 'likes' ? sortOrder : 'asc'}
                                        onClick={createSortHandler('likes')}>
                                        Likes
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {displayTemplates.map((template) => (
                                <TableRow key={template.id}>
                                    <TableCell sx={{ maxWidth: 200, wordWrap: 'break-word', color: primaryColor }}>
                                        <Link to={`/templates/${template.id}`} style={{ color: primaryColor }}>
                                            {template.name}
                                        </Link>
                                    </TableCell>                                    <TableCell>{template.level}</TableCell>
                                    <TableCell sx={{ color: primaryColor }}>{template.ownerName}</TableCell>
                                    <TableCell>{daysAgo(template.createdOn)}</TableCell>
                                    <TableCell>{template.likes ? template.likes.length : 0}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={displayTemplates.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Snackbar
                open={error !== null}
                autoHideDuration={6000}
                onClose={() => setError(null)}
                message="Error connecting to Firestore. Retrying..."
            />
        </React.Fragment>
    );
};

export default PublicTemplates;
