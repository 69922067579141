import React, { useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { db } from '../../firebase/config';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import {
    AppBar,
    Tabs,
    Tab,
    Container,
    Box,
} from '@mui/material';
import { useAuthContext } from '../../hooks/useAuthContext';

const Admin = () => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const { user } = useAuthContext();
    const [selectedTab, setSelectedTab] = useState(0);

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                // User is signed in, check for admin role
                const userRef = doc(db, 'users', user.uid);
                const docSnap = await getDoc(userRef);

                if (docSnap.exists() && docSnap.data().admin) {
                    setIsAdmin(true);
                } else {
                    console.log('No admin rights');
                }
            } else {
                // User is signed out
                console.log('User not signed in');
            }
            setLoading(false);
        });
    }, []);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!isAdmin) {
        return <div>You do not have permission to view this page.</div>;
    }

    return (
        <div>
            <AppBar position="static">
                <Tabs value={selectedTab} onChange={handleTabChange}>
                    {/*<Tab label="Shared Templates" component={Link} to="/admin/shared-templates" />*/}
                    <Tab label="Items" component={Link} to="/admin/items" />
                    <Tab label="Add Item" component={Link} to="/admin/items/add" />
                </Tabs>
            </AppBar>

            <Container fixed>
                <Box mt={2}>
                    <Outlet />
                </Box>
            </Container>
        </div>
    );
};

export default Admin;
