import React, { useState, useCallback } from 'react';
import { storage } from '../../firebase/config';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { updateProfile } from 'firebase/auth';
import { useAuthContext } from "../../hooks/useAuthContext";
import { Grid, Button, Input, Snackbar, Alert, Typography, IconButton, Paper } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera'; // Optional: If you want to use an icon
import FileCopyIcon from '@mui/icons-material/FileCopy';


const MAX_FILE_SIZE = 1048576; // 1MB in bytes

const Profile = () => {
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const { user } = useAuthContext();

    const copyToClipboard = useCallback(() => {
        navigator.clipboard.writeText(user.uid).then(() => {
            setSnackbarMessage('User ID copied to clipboard!');
            setSnackbarSeverity('success');
            setOpenSnackbar(true);
        }, () => {
            setSnackbarMessage('Failed to copy User ID.');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        });
    }, [user.uid]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > MAX_FILE_SIZE) {
                setSnackbarMessage('File size should be less than 1MB.');
                setSnackbarSeverity('warning');
                setOpenSnackbar(true);
                return; // Exit the function if file size is too big
            }

            setImage(file);
            const reader = new FileReader();
            reader.onloadend = () => setImagePreview(reader.result);
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = () => {
        if (image && image.size <= MAX_FILE_SIZE && user) {
            const imageRef = ref(storage, `avatars/${user.uid}/${image.name}`);
            uploadBytes(imageRef, image).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadURL) => {
                    updateProfile(user, { photoURL: downloadURL }).then(() => {
                        setSnackbarMessage('Avatar updated successfully!');
                        setSnackbarSeverity('success');
                        setOpenSnackbar(true);
                    }).catch((error) => {
                        setSnackbarMessage(`Error updating avatar: ${error.message}`);
                        setSnackbarSeverity('error');
                        setOpenSnackbar(true);
                    });
                });
            }).catch((error) => {
                setSnackbarMessage(`Error uploading file: ${error.message}`);
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
            });
        } else if (!image) {
            setSnackbarMessage('Please select an image to upload.');
            setSnackbarSeverity('warning');
            setOpenSnackbar(true);
        } else {
            setSnackbarMessage('File size should be less than 1MB.');
            setSnackbarSeverity('warning');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ margin: '20px 0' }}>
            <Grid item xs={12}>
                <Paper style={{ padding: '20px', marginBottom: '20px' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            {imagePreview && (
                                <img src={imagePreview} alt="Avatar Preview" style={{ width: '200px', height: '200px', borderRadius: '50%' }} />
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="body1" gutterBottom>
                                Max file size: 1MB. Please use a square image of 200x200px.
                            </Typography>
                            <label htmlFor="icon-button-file">
                                <Input
                                    accept="image/*"
                                    id="icon-button-file"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    startIcon={<PhotoCamera />}
                                >
                                    Choose File
                                </Button>
                            </label>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleUpload}
                                style={{ marginLeft: '10px' }}
                            >
                                Upload Avatar
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>

                <Paper style={{ padding: '20px' }}>
                    <Typography variant="body1" gutterBottom>
                        Your User ID: {user.uid}
                        <IconButton onClick={copyToClipboard} size="small">
                            <FileCopyIcon fontSize="inherit" />
                        </IconButton>
                    </Typography>
                </Paper>
            </Grid>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid>
    );
};

export default Profile;
