import { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase/config'; // Import your Firebase auth instance

const usePasswordReset = () => {
    const [resetPasswordError, setResetPasswordError] = useState('');
    const [resetPasswordSuccess, setResetPasswordSuccess] = useState('');

    const sendResetEmail = async (email) => {
        try {
            await sendPasswordResetEmail(auth, email);
            setResetPasswordSuccess('Password reset email sent successfully. Please check your inbox.');
            setResetPasswordError('');
        } catch (error) {
            setResetPasswordError('Failed to send password reset email. Please try again.');
            console.error('Password reset error:', error);
        }
    };

    return { sendResetEmail, resetPasswordError, resetPasswordSuccess };
};

export default usePasswordReset;
