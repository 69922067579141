import { createContext, useReducer, useEffect } from 'react'
import { auth } from '../firebase/config'
import { onAuthStateChanged } from 'firebase/auth'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            return { ...state, user: action.payload.user, token: action.payload.token };
        case 'LOGOUT':
            return { ...state, user: null, token: null };
        case 'AUTH_IS_READY':
            // Ensure action.payload is not undefined before accessing its properties
            return {
                user: action.payload ? action.payload.user : null,
                authIsReady: true,
                token: action.payload ? action.payload.token : null
            };
        default:
            return state;
    }
};

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false,
        token: localStorage.getItem('authToken')
    })

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await user.getIdToken();
                localStorage.setItem('authToken', token); // Store token in local storage
                dispatch({ type: 'AUTH_IS_READY', payload: { user, token } });
            } else {
                localStorage.removeItem('authToken'); // Clear token from local storage
                dispatch({ type: 'AUTH_IS_READY', payload: { user: null, token: null } });
            }
            unsub();
        });
    }, []);

    // console.log('AuthContext state:', state)

    return (
        <AuthContext.Provider value={{ ...state, dispatch }}>
            { children }
        </AuthContext.Provider>
    )

}