import React from 'react';
import { Paper, Typography, List, ListItemButton, ListItemText, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import {useTheme} from "@mui/material/styles";

function TemplateEquipmentViewOnly({ gearBySlot, handleItemClick, selectedItem, weaponConfig, setWeaponConfig }) {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const gearSlots = ['Torso', 'Helm', 'Arms', 'Hands', 'Legs', 'Feet', 'Necklace', 'Cloak', 'Jewel', 'Belt', 'L Ring', 'R Ring', 'L Wrist', 'R Wrist', 'Mythirian', 'Main Hand', 'Off Hand', 'Two-Hand', 'Ranged'];

    const handleWeaponConfigChange = (event) => {
        setWeaponConfig(event.target.value);
    };

    const selectedStyle = {
        boxShadow: `inset 0 0 0 4px ${primaryColor}`,
        backgroundColor: 'transparent'
    };

    return (
        <Paper style={{ padding: '8px', flex: 1 }}>
            <Typography variant="h6">Items</Typography>
            <RadioGroup row value={weaponConfig} onChange={handleWeaponConfigChange}>
                <FormControlLabel value="Main Hand / Off Hand" control={<Radio />} label="MH / OH" />
                <FormControlLabel value="Two-Hand" control={<Radio />} label="Two-Hand" />
                <FormControlLabel value="Ranged" control={<Radio />} label="Ranged" />
                <FormControlLabel value="Weaponless" control={<Radio />} label="Weaponless" />
            </RadioGroup>
            <List component="nav" dense>
                {gearSlots.map(slot => (
                    <ListItemButton
                        key={slot}
                        onClick={() => handleItemClick(slot)}
                        selected={selectedItem === slot}
                        style={selectedItem === slot ? selectedStyle : {}}
                    >
                        <ListItemText primary={slot}
                                      primaryTypographyProps={{ style: { fontSize: '0.9rem' } }}
                                      secondary={gearBySlot[slot]?.name || ''}
                                      secondaryTypographyProps={{ style: { color: primaryColor } }}
                        />
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
}

export default TemplateEquipmentViewOnly;
