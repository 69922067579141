import React, {useState} from 'react';
import {Paper, Typography, Grid, Tabs, Tab, Tooltip} from '@mui/material';

function CharacterBonuses({ statistics, selectedClass }) {
    const [tabValue, setTabValue] = useState(0);

    const classRestrictedBonuses = {
        'Armsman': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)', 'Resistance Pierce'],
        'Cabalist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Cleric': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Friar': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Heretic': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Infiltrator': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)', 'Mythical Spell Focus', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Power Regen', 'Mythical Power Regen', 'Radiant Aura'],
        'Mauler': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Mercenary': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Minstrel': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Necromancer': ['Mythical Physical Defense', 'Radiant Aura', 'Buff Bonus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Paladin': ['Mythical Physical Defense', 'Radiant Aura', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Reaver': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Scout': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Sorcerer': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Theurgist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Wizard': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Berserker': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Bonedancer': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Healer': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Hunter': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Runemaster': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Savage': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Shadowblade': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Shaman': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Skald': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Spiritmaster': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Thane': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Valkyrie': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Warlock': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Warrior': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Animist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Bainshee': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Bard': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Blademaster': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Champion': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Druid': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Eldritch': ['Mythical Physical Defense', 'Radiant Aura', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Enchanter': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Hero': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Mentalist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Nightshade': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Ranger': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Valewalker': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Vampiir': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
        'Warden': ['Mythical Physical Defense', 'Magic Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)']
    };

    const renderBonusValue = (bonus) => {
        const totalCap = bonus.cap + bonus.mythicalCap;
        const textStyle = bonus.value > totalCap ? { color: 'red' } : {}; // Add red color if value exceeds total cap
        const tooltipTitle = `Value: ${bonus.value}, Cap: ${bonus.cap}, Mythical Cap: ${bonus.mythicalCap}`;

        return (
            <Tooltip title={tooltipTitle}>
                <Typography variant="body2" style={{ marginLeft: 'auto', ...textStyle }}>
                    {bonus.value}/{totalCap}
                </Typography>
            </Tooltip>
        );
    };

    const groupAssignments = {
        group1: ["Melee Damage", "Melee Speed", "Style Damage", "Mythical DPS", "Magic Damage", "Cast Speed", "Spell Range", "Spell Duration", "Resistance Pierce", "Arcane Siphon", "Power Pool", "Power Pool Cap", "Mythical Parry", "Mythical Evade", "Mythical Block", "Mythical Spell Focus",],
        group2: ["Heal Bonus", "Buff Bonus", "Debuff Bonus", "Conversion", "CC Duration Decrease", "Health Regeneration", "Mythical Health Regen", "Mythical Power Regen", "Power Regen", "Mythical Endurance Regen"],
        group3: ["Fatigue", "Fatigue Cap", "Mythical Realm Points", "Radiant Aura", "XP Bonus", "Gold Bonus", "Realm Point Bonus", "Bounty Point Bonus", "Safe Fall", "Mythical Coin",]
    };

    // Function to group bonuses into columns considering the class restrictions and manual assignments
    const groupBonuses = () => {
        const restrictedBonuses = classRestrictedBonuses[selectedClass] || [];
        const group1 = [], group2 = [], group3 = [];

        Object.keys(statistics).forEach(key => {
            if (!statistics[key].displayName || restrictedBonuses.includes(statistics[key].displayName)) {
                return;
            }

            if (groupAssignments.group1.includes(statistics[key].displayName)) {
                group1.push(key);
            } else if (groupAssignments.group2.includes(statistics[key].displayName)) {
                group2.push(key);
            } else if (groupAssignments.group3.includes(statistics[key].displayName)) {
                group3.push(key);
            }
        });

        return [group1, group2, group3];
    };

    // Generate groups of bonuses
    const [bonusesGroup1, bonusesGroup2, bonusesGroup3] = groupBonuses();



    const renderAbilities = () => {
        const abilities = statistics.abilities || {};
        return Object.values(abilities)
            .filter(ability => ability.magic_type === "Charge") // Filter for abilities with "Charge" magic type
            .map((ability, index) => (
                <div key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="body2">[{ability.magic_type}] - {ability.spell}</Typography>
                </div>
            ));
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const restrictedBonuses = classRestrictedBonuses[selectedClass] || [];

    const bonusKeys = Object.keys(statistics)
        .filter(key =>
            statistics[key].displayName && !restrictedBonuses.includes(statistics[key].displayName)
        )
        .sort((a, b) => {
            const displayNameA = String(statistics[a].displayName);
            const displayNameB = String(statistics[b].displayName);
            return displayNameA.localeCompare(displayNameB);
        });

    const numberOfColumns = 3;  // Adjust the number of columns as needed
    const bonusesPerColumn = Math.ceil(bonusKeys.length / numberOfColumns);

    return (
        <Paper style={{ padding: '16px', flex: 1 }}>
            <Tabs value={tabValue} onChange={handleTabChange} centered>
                <Tab label="Bonuses" />
                <Tab label="Abilities" />
            </Tabs>

            {tabValue === 0 && (
                <Grid container spacing={2}>
                    {[bonusesGroup1, bonusesGroup2, bonusesGroup3].map((group, index) => (
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4} key={index}>
                            {group.map(key => (
                                <div key={key} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="body2">{statistics[key].displayName}:</Typography>
                                    {renderBonusValue(statistics[key])}
                                </div>
                            ))}
                        </Grid>
                    ))}
                </Grid>
            )}
            {tabValue === 1 && (
                <Grid container style={{ padding: '8px' }}>
                    {renderAbilities()}
                </Grid>
                )}
        </Paper>
    );
}

export default CharacterBonuses;
