import React, { useState } from 'react';
import useLogin from "../../hooks/useLogin";
import useSignup from "../../hooks/useSignup";
import usePasswordReset from "../../hooks/usePasswordReset";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Alert from '@mui/material/Alert';

const cardStyles = {
    maxWidth: 400,
    margin: '0 auto',
    marginTop: 100,
    padding: 16,
};

const typographyStyles = {
    cursor: 'pointer',
    marginTop: 16,
};

const Login = () => {
    const { login } = useLogin();
    const { signup } = useSignup();
    const { sendResetEmail, resetPasswordError, resetPasswordSuccess } = usePasswordReset(); // Use the hook

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [isLogin, setIsLogin] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const enableSignup = true;

    const handleLoginOrSignup = async (e) => {
        e.preventDefault();
        try {
            if (isLogin) {
                await login(email, password);
            } else {
                if (enableSignup) {
                    await signup(email, password, displayName);
                } else {
                    throw new Error('Sign up is currently disabled.');
                }
            }
        } catch (error) {
            if (error.code === 'auth/wrong-password' || error.code === 'auth/user-not-found') {
                setErrorMessage('Incorrect email or password. Please try again.');
            } else {
                setErrorMessage(error.message || 'Login failed. Please try again.');
            }
        }
    };

    const toggleLoginSignup = () => {
        if (enableSignup) {
            setIsLogin(!isLogin);
        }
    };

    const handlePasswordReset = async () => {
        await sendResetEmail(email); // Invoke the password reset function
    };

    return (
        <>
            <Card style={cardStyles}>
                <CardContent>
                    <Typography variant="h4">{isLogin ? 'Sign In' : 'Sign Up'}</Typography>
                    <form onSubmit={handleLoginOrSignup}>
                        <TextField
                            label="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        {!isLogin && (
                            <TextField
                                label="Display Name"
                                type="text"
                                value={displayName}
                                onChange={(e) => setDisplayName(e.target.value)}
                                fullWidth
                                margin="normal"
                            />
                        )}
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            {isLogin ? 'Sign In' : 'Sign Up'}
                        </Button>
                    </form>
                    {isLogin && (
                        <Typography variant="body2" style={{ marginTop: '8px' }} onClick={handlePasswordReset} style={typographyStyles}>
                            Forgot password?
                        </Typography>
                    )}
                    {enableSignup && (
                        <Typography variant="body2" onClick={toggleLoginSignup} style={typographyStyles}>
                            {isLogin ? "Don't have an account? Sign Up" : "Already have an account? Sign In"}
                        </Typography>
                    )}
                </CardContent>
            </Card>
            {errorMessage && (
                <Alert severity="error" style={{ margin: '16px 0' }}>
                    {errorMessage}
                </Alert>
            )}
            {resetPasswordError && (
                <Alert severity="error" style={{ margin: '16px 0' }}>
                    {resetPasswordError}
                </Alert>
            )}
            {resetPasswordSuccess && (
                <Alert severity="success" style={{ margin: '16px 0' }}>
                    {resetPasswordSuccess}
                </Alert>
            )}
        </>
    );
};

export default Login;