import React from 'react';
import { Paper, Typography, Tooltip, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CharacterResistances = ({ statistics }) => {
    const resistanceKeys = ['Crush', 'Slash', 'Thrust', 'Heat', 'Cold', 'Matter', 'Body', 'Spirit', 'Energy'];
    const theme = useTheme();

    const CustomTooltip = ({ title, children }) => (
        <Tooltip
            title={
                <Box style={{ padding: 8, fontSize: '0.875rem' }}>
                    {title.split('\n').map((line, index) => (
                        <div key={index}>{line}</div>
                    ))}
                </Box>
            }
            arrow
        >
            {children}
        </Tooltip>
    );

    const renderValue = (value, baseCap, mythicalCap) => {
        const totalCap = Math.min(baseCap + mythicalCap, 127);
        const percentage = (value / totalCap) * 100;

        let color;
        if (percentage >= 100) {
            color = theme.palette.primary.main;
        } else if (percentage >= 90) {
            color = 'green';
        } else if (percentage >= 80) {
            color = 'orange';
        } else {
            color = 'red';
        }

        const tooltipTitle = `Base Cap: ${baseCap}, Mythical Cap: ${mythicalCap}`;

        return (
            <CustomTooltip title={tooltipTitle}>
                <Typography variant="body2" style={{ color }}>
                    {value}/{totalCap}
                </Typography>
            </CustomTooltip>
        );
    };

    const renderRow = (key) => {
        if (!statistics[key]) return null;
        const { value, mythicalCap } = statistics[key];
        const baseCap = 26; // Base cap for resistances

        return (
            <div key={key} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body2">{key}:</Typography>
                {renderValue(value, baseCap, mythicalCap)}
            </div>
        );
    };

    return (
        <Paper style={{ padding: '16px', flex: 1, height: '100%' }}>
            <Typography variant="body1">Resistances</Typography>
            <hr />
            <div>
                {resistanceKeys.map(renderRow)}
            </div>
        </Paper>
    );
};

export default CharacterResistances;
