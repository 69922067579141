import React, { useState, useEffect } from 'react';
import {
    Button,
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
    Select,
    MenuItem,
    Snackbar
} from '@mui/material';

const Skills = ({ characterData, updateCharacterData }) => {
    const { level, realm, className } = characterData;
    const [skills, setSkills] = useState({});
    const [skillFactor, setSkillFactor] = useState(null);
    const [skillPoints, setSkillPoints] = useState(characterData.skillPoints || { total: 0, used: 0, remaining: 0 });
    const [pointsPerSkill, setPointsPerSkill] = useState(characterData.skills || {});
    const [hoveredSkill, setHoveredSkill] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    useEffect(() => {
        if (realm && className) {
            loadSkillsAndFactor();
        }
    }, [realm, className]);

    useEffect(() => {
        if (skillFactor !== null) {
            const totalPoints = calculatePointsForLevel(level, skillFactor);
            const initialUsedPoints = calculateUsedPoints(pointsPerSkill, level);
            const remainingPoints = totalPoints - initialUsedPoints;

            const updatedSkillPoints = {
                total: totalPoints,
                used: initialUsedPoints,
                remaining: remainingPoints,
            };

            setSkillPoints(updatedSkillPoints);

            // Save to characterData
            updateCharacterData({
                skills: pointsPerSkill,
                skillPoints: updatedSkillPoints,
            });
        }
    }, [level, skillFactor, pointsPerSkill]);

    const loadSkillsAndFactor = async () => {
        try {
            const classData = await import(`../../data/classes/${realm}/${className}.json`);
            const loadedSkills = classData.skills || {};
            setSkills(loadedSkills);
            setSkillFactor(classData['skill factor']);

            if (Object.keys(characterData.skills).length === 0) {
                resetPoints(loadedSkills, level, classData['skill factor']); // Initialize points if not present
            } else {
                setPointsPerSkill(characterData.skills);
                setSkillPoints(characterData.skillPoints || { total: 0, used: 0, remaining: 0 });
            }
        } catch (error) {
            console.error('Error loading class data:', error);
            setSkills({});
        }
    };

    const resetPoints = (loadedSkills, level, skillFactor) => {
        const initialPoints = {};
        Object.keys(loadedSkills).forEach(skill => {
            initialPoints[skill] = 1; // Initialize each skill at level 1
        });

        setPointsPerSkill(initialPoints);

        const totalPoints = calculatePointsForLevel(level, skillFactor);
        const initialUsedPoints = calculateUsedPoints(initialPoints, level);
        const remainingPoints = totalPoints - initialUsedPoints;

        const newSkillPoints = {
            total: totalPoints,
            used: initialUsedPoints,
            remaining: remainingPoints,
        };

        setSkillPoints(newSkillPoints);

        updateCharacterData({
            skills: initialPoints,
            skillPoints: newSkillPoints,
        });
    };

    const handleSkillPointsChange = (skillName, newValue) => {
        const parsedValue = parseInt(newValue, 10) || 0;

        if (parsedValue < 1 || parsedValue > 50) {
            return;
        }

        if (parsedValue > level) {
            openSnackbar(`You cannot assign more points than your character's level (${level}).`);
            return;
        }

        const currentPoints = pointsPerSkill[skillName] || 1;
        const pointDifference = parsedValue - currentPoints;

        const potentialRemainingPoints = skillPoints.remaining - pointDifference;

        if (potentialRemainingPoints < 0) {
            openSnackbar("You don't have enough remaining points to assign.");
            return;
        }

        const updatedPointsPerSkill = { ...pointsPerSkill, [skillName]: parsedValue };
        setPointsPerSkill(updatedPointsPerSkill);

        const updatedUsedPoints = calculateUsedPoints(updatedPointsPerSkill, level);
        const remainingPoints = skillPoints.total - updatedUsedPoints;

        const newSkillPoints = {
            total: skillPoints.total,
            used: updatedUsedPoints,
            remaining: remainingPoints,
        };

        setSkillPoints(newSkillPoints);

        updateCharacterData({
            skills: updatedPointsPerSkill,
            skillPoints: newSkillPoints,
        });
    };

    const handleSkillButtonClick = (skillName, targetLevel) => {
        const currentLevel = pointsPerSkill[skillName] || 1;
        const pointsNeeded = calculatePointsNeeded(currentLevel, targetLevel);

        if (skillPoints.remaining >= pointsNeeded) {
            handleSkillPointsChange(skillName, targetLevel);
        } else {
            openSnackbar("Not enough remaining points to assign.");
        }
    };

    const calculatePointsForLevel = (level, classMultiplier) => {
        let totalPoints = 0;

        for (let i = 2; i <= 5 && i <= level; i++) {
            totalPoints += i;
        }

        for (let i = 6; i <= level; i++) {
            totalPoints += Math.floor(i * classMultiplier);
        }

        for (let i = 40.5; i <= 49.5 && i <= level; i += 1) {
            totalPoints += Math.floor(i * classMultiplier / 2);
        }

        return totalPoints;
    };

    const calculateUsedPoints = (pointsPerSkill, level) => {
        let usedPoints = 0;

        for (const [skill, points] of Object.entries(pointsPerSkill)) {
            for (let i = 2; i <= points; i++) {
                usedPoints += i;
            }
        }

        return usedPoints;
    };

    const calculatePointsNeeded = (currentLevel, targetLevel) => {
        let pointsNeeded = 0;
        for (let i = currentLevel + 1; i <= targetLevel; i++) {
            pointsNeeded += i;
        }
        return pointsNeeded;
    };

    const openSnackbar = (message) => {
        setSnackbarOpen({ open: true, message });
    };

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <Grid container spacing={2} justifyContent="space-between" alignItems="flex-start">
            <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Button variant="contained" color="secondary" onClick={() => resetPoints(skills)}>
                                        Reset
                                    </Button>
                                </TableCell>
                                <TableCell>Levels</TableCell>
                                <TableCell>Points</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(skills).map(([skillName, levels]) => (
                                <TableRow key={skillName}>
                                    <TableCell component="th" scope="row">{skillName}</TableCell>
                                    <TableCell align="center">
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {levels.map((skillLevel, index) => {
                                                const isSkillAcquired = pointsPerSkill[skillName] >= skillLevel.level;
                                                return (
                                                    <Button
                                                        key={index}
                                                        variant="outlined"
                                                        size="small"
                                                        sx={{
                                                            minWidth: '24px',
                                                            height: '24px',
                                                            padding: 0,
                                                            backgroundColor: isSkillAcquired ? 'primary.main' : '',
                                                            color: isSkillAcquired ? 'background.paper' : '',
                                                            ':hover': {
                                                                backgroundColor: isSkillAcquired ? 'primary.dark' : '',
                                                            }
                                                        }}
                                                        title={`${skillLevel.level} ${skillLevel.name}`}
                                                        onClick={() => handleSkillButtonClick(skillName, skillLevel.level)}
                                                        disabled={skillPoints.remaining < calculatePointsNeeded(pointsPerSkill[skillName] || 1, skillLevel.level)}
                                                        onMouseEnter={() => setHoveredSkill(skillLevel)}
                                                        onMouseLeave={() => setHoveredSkill(null)}
                                                    >
                                                        {skillLevel.level}
                                                    </Button>
                                                );
                                            })}
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ minWidth: '24px', height: '24px', padding: 0 }}
                                                onClick={() => handleSkillPointsChange(skillName, (pointsPerSkill[skillName] || 0) - 1)}
                                                disabled={pointsPerSkill[skillName] <= 1}
                                            >
                                                -
                                            </Button>
                                            <Select
                                                value={pointsPerSkill[skillName] || 1}
                                                onChange={(e) => handleSkillPointsChange(skillName, e.target.value)}
                                                sx={{ width: '75px', height: '24px', textAlign: 'center' }}
                                            >
                                                {Array.from({ length: 50 }, (_, i) => (
                                                    <MenuItem key={i + 1} value={i + 1} disabled={skillPoints.remaining < calculatePointsNeeded(pointsPerSkill[skillName] || 1, i + 1)}>
                                                        {i + 1}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                sx={{ minWidth: '24px', height: '24px', padding: 0 }}
                                                onClick={() => handleSkillPointsChange(skillName, (pointsPerSkill[skillName] || 0) + 1)}
                                                disabled={skillPoints.remaining < calculatePointsNeeded(pointsPerSkill[skillName] || 1, pointsPerSkill[skillName] + 1)}
                                            >
                                                +
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: '16px' }}>
                            <Typography variant="h6">Skill Points</Typography>
                            <Typography>Total: {skillPoints.total}</Typography>
                            <Typography>Used: {skillPoints.used}</Typography>
                            <Typography>Remaining: {skillPoints.remaining}</Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={3} sx={{ padding: '16px' }}>
                            {hoveredSkill && (
                                <div>
                                    <Typography variant="subtitle1">
                                        [{hoveredSkill.level}] {hoveredSkill.name}
                                    </Typography>
                                    {hoveredSkill.opening && <Typography>Opening: {hoveredSkill.opening}</Typography>}
                                    {hoveredSkill.followup && <Typography>Follow-up: {hoveredSkill.followup}</Typography>}
                                    {hoveredSkill.endurance && <Typography>Endurance: {hoveredSkill.endurance}</Typography>}
                                    {hoveredSkill.damage && <Typography>Damage: {hoveredSkill.damage}</Typography>}
                                    {hoveredSkill.hit && <Typography>Hit: {hoveredSkill.hit}</Typography>}
                                    {hoveredSkill.defense && <Typography>Defense: {hoveredSkill.defense}</Typography>}
                                    {hoveredSkill.description && <Typography>Description: {hoveredSkill.description}</Typography>}
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>

            <Snackbar
                open={snackbarOpen.open}
                autoHideDuration={3000}
                onClose={handleCloseSnackbar}
                message={snackbarOpen.message}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </Grid>
    );
};

export default Skills;
