import React, { useState } from 'react';
import {
    TableCell, TableRow, IconButton, Collapse, Box, Typography, Button, Chip, Tooltip
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ItemDetails from "../templates/shared/ItemDetails";

function CollapsibleRow({ item, onSelectItem }) {
    const [open, setOpen] = useState(false);

    const getChipColor = (tag) => {
        switch (tag) {
            case 'df': return 'warning';
            case 'otherworldly': return 'secondary';
            case 'verified': return 'success';
            case 'curse': return 'error';
            case 'set': return 'primary';
            default: return 'default';
        }
    };

    const renderTagIndicators = () => {
        return item.tags && (
            <span style={{ marginLeft: 8 }}>
            {item.tags.sort().map((tag, index) => {
                const color = getChipColor(tag);
                let style = {};

                if (color === 'warning') {
                    style = { backgroundColor: '#ff9800', marginRight: 4 };
                } else if (color === 'success') {
                    style = { backgroundColor: '#4caf50', marginRight: 4 };
                } else {
                    style = { marginRight: 4 };
                }

                const label = tag.charAt(0).toUpperCase() + tag.slice(1); // Capitalize first letter

                return (
                    <Tooltip key={index} title={label}>
                        <Chip label="" size="small" style={style} color={color} />
                    </Tooltip>
                );
            })}
        </span>
        );
    };

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>
                    <IconButton size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.name}
                </TableCell>
                <TableCell>{item.utility}</TableCell>
                {/*<TableCell>*/}
                {/*    {renderTagIndicators()}*/}
                {/*</TableCell>*/}
                <TableCell>
                    <Button variant="contained" color="primary" onClick={() => onSelectItem(item, true)}>
                        Select
                    </Button>
                    <Button variant="outlined" color="secondary" onClick={() => onSelectItem(item, false)} style={{ marginLeft: '8px' }}>
                        Add
                    </Button>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                Details
                            </Typography>
                            {/* Render the entire delve_text content with line breaks preserved */}
                            <ItemDetails item={item} />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default CollapsibleRow;
