import React from 'react';
import {
    Paper,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material/styles';

const TemplateInventory = ({ inventory, selectedSlot, selectedItem, selectItemFromInventory, removeItemFromInventory, renderItemDetails }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    return (
        <Paper style={{ padding: '8px', flex: 1 }}>
            {selectedSlot && (
                <Typography variant="h6" style={{ marginBottom: '8px' }}>
                    {selectedItem} Inventory
                </Typography>
            )}
            <List component="nav" dense>
                {selectedSlot && inventory[selectedItem] && inventory[selectedItem].map(item => (
                    <ListItem
                        button
                        key={item.id}
                        onClick={() => selectItemFromInventory(selectedItem, item.id)}
                    >
                        <ListItemText primary={item.name} primaryTypographyProps={{ style: { color: primaryColor } }} />
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(event) => {
                                event.stopPropagation();
                                removeItemFromInventory(selectedItem, item.id);
                            }}
                            size="small"
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

export default TemplateInventory;
