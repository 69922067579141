import React from 'react';
import { Typography, Chip, Grid, Table, TableBody, TableCell, TableRow, useTheme } from '@mui/material';
import DiamondIcon from '@mui/icons-material/Diamond';

const ItemDetails = ({ item }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    if (!item) {
        return <Typography variant="body1">No item selected.</Typography>;
    }

    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    const getChipColor = (tag) => {
        switch (tag) {
            case 'df': return 'warning';
            case 'otherworldly': return 'secondary';
            case 'verified': return 'success';
            case 'curse': return 'error';
            case 'set': return 'primary';
            default: return 'default';
        }
    };

    const rowStyle = {
        padding: '2px 0',
        transition: 'background-color 0.3s',
    };

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={12}>
                <Typography variant="h6" style={{ color: primaryColor }}>{item.name}</Typography>
            </Grid>
            {item.tags && (
                <Grid item xs={12}>
                    {item.tags.sort().map(tag => (
                        <Chip
                            key={`${item.id}-${tag}`}
                            label={capitalizeFirstLetter(tag)}
                            color={getChipColor(tag)}
                            size="small"
                            style={{ marginRight: 4 }}
                        />
                    ))}
                </Grid>
            )}
            <Grid item xs={12}>
                <Typography variant="caption">Utility: {item.utility}</Typography>
            </Grid>
            {item.category === 'Weapon' && (
                <Grid item xs={12}>
                    <Typography variant="body2">DPS: {item.type_data.dps}</Typography>
                    <Typography variant="body2">Speed: {item.type_data.speed}</Typography>
                    <Typography variant="body2">Damage Type: {item.type_data.damage_type}</Typography>
                    <Typography variant="body2">Skill Used: {item.type_data.skill_used}</Typography>
                </Grid>
            )}
            {item.note && (
                <Grid item xs={12}>
                    <Typography variant="body2">{item.note}</Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Table size="small">
                    <TableBody>
                        {item.bonuses.map((bonus, index) => (
                            <TableRow
                                key={`${item.id}-${index}`}
                                style={rowStyle}
                                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme.palette.action.hover}
                                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                            >
                                <TableCell style={{ borderBottom: '1px solid', borderTop: '1px solid', padding: '2px 8px', color: primaryColor }}>
                                    {bonus.isGem && <DiamondIcon color="primary" style={{ fontSize: 'small', verticalAlign: 'middle', marginRight: 4 }} />}
                                    {bonus.type === "Stats" ? bonus.id :
                                        bonus.type === "Skills" ? bonus.id :
                                            bonus.type === "Toa Overcap" ? `${bonus.id} Cap` :
                                                bonus.type === "Mythical Cap Increase" ? `Mythical ${bonus.id} Cap` :
                                                    bonus.type === "Mythical Resist and Cap" ? `Mythical ${bonus.id} Resist and Cap` :
                                                        bonus.type === "Resistance" ? `${bonus.id} ${bonus.type}` :
                                                            bonus.type === "Toa Artifact" ? bonus.id :
                                                                bonus.type}
                                </TableCell>
                                <TableCell style={{ borderBottom: '1px solid', borderTop: '1px solid', padding: '2px 8px', color: primaryColor }}>
                                    {bonus.value}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
            {item.abilities && item.abilities.map((ability, index) => (
                <Grid item xs={12} key={`${item.id}-${index}`}>
                    <Typography variant="body2">
                        [{ability.position.replace('/use', '')}] [{ability.magic_type}] - {ability.spell}
                    </Typography>
                </Grid>
            ))}
        </Grid>
    );
};

export default ItemDetails;
