import React from 'react';
import { Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, useTheme } from '@mui/material';

const classRestrictedStats = {
    'Armsman': ['Piety', 'Empathy', 'Intelligence', 'Acuity', 'Charisma'],
    'Cabalist': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Cleric': ['Strength', 'Quickness', 'Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Friar': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Heretic': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Infiltrator': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Mauler': ['Intelligence', 'Empathy', 'Piety', 'Charisma', 'Acuity'],
    'Mercenary': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Minstrel': ['Intelligence', 'Piety', 'Empathy', 'Acuity'],
    'Necromancer': ['Strength', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Paladin': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Reaver': ['Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Scout': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Sorcerer': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Theurgist': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Wizard': ['Strength', 'Quickness', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Berserker': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Bonedancer': ['Strength', 'Quickness', 'Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Healer': ['Strength', 'Quickness', 'Intelligence', 'Empathy', 'Charisma', 'Acuity'],
    'Hunter': ['Intelligence', 'Empathy', 'Piety', 'Charisma', 'Acuity'],
    'Runemaster': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Savage': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Shadowblade': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Shaman': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Skald': ['Intelligence', 'Piety', 'Empathy', 'Acuity'],
    'Spiritmaster': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Thane': ['Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Valkyrie': ['Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Warlock': ['Strength', 'Quickness', 'Intelligence', 'Charisma', 'Empathy', 'Acuity'],
    'Warrior': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Animist': ['Strength', 'Quickness', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Bainshee': ['Strength', 'Quickness', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Bard': ['Intelligence', 'Piety', 'Empathy', 'Acuity'],
    'Blademaster': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Champion': ['Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Druid': ['Strength', 'Quickness', 'Intelligence', 'Piety', 'Charisma', 'Acuity'],
    'Eldritch': ['Strength', 'Quickness', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Enchanter': ['Strength', 'Quickness', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Hero': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Mentalist': ['Strength', 'Quickness', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Nightshade': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Ranger': ['Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'],
    'Valewalker': ['Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Vampiir': ['Intelligence', 'Piety', 'Charisma', 'Empathy', 'Acuity'],
    'Warden': ['Intelligence', 'Piety', 'Charisma', 'Acuity']
};

const TemplateStatisticsNew = ({ statistics, selectedClass }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const restrictedStats = classRestrictedStats[selectedClass] || [];
    const statKeys = ['Strength', 'Constitution', 'Dexterity', 'Quickness', 'Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'];
    const bonusKeys = ['Hit Points', 'Power', 'Armor Factor (AF)'];

    const rowStyle = {
        padding: '2px 0',
        transition: 'background-color 0.3s',
    };

    const cellStyle = {
        borderBottom: '1px solid',
        borderTop: '1px solid',
        padding: '2px 8px',
        fontSize: '0.8rem',
        color: primaryColor,
    };

    const renderRow = (key, isStat) => {
        if (restrictedStats?.includes(key) || !statistics[key]) return null;
        const { value, cap, mythicalCap } = statistics[key];
        const baseCap = isStat ? 75 : 26;
        const totalCap = isStat ? Math.min(baseCap + cap + mythicalCap, 127) : baseCap + cap + mythicalCap;

        const differenceToMaxItem = value - totalCap;

        const colorItem = differenceToMaxItem >= 0 ? 'green' : 'red';

        return (
            <TableRow
                key={key}
                style={rowStyle}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme.palette.action.hover}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            >
                <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }}>{key}</TableCell>
                <TableCell style={cellStyle}>{value}</TableCell>
                <TableCell style={cellStyle}><span style={{ color: colorItem }}>{differenceToMaxItem}</span></TableCell>
                <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>{totalCap}</TableCell>
                <TableCell style={cellStyle}>{baseCap}</TableCell>
                <TableCell style={cellStyle}>{cap}</TableCell>
                <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>{mythicalCap}</TableCell>
            </TableRow>
        );
    };

    const renderBonusRow = (key) => {
        const bonus = statistics[key];
        if (!bonus) return null;

        let baseCap = 0;
        if (key === 'Hit Points') {
            baseCap = 200;
        } else if (key === 'Power') {
            baseCap = 26;
        } else {
            baseCap = 50;
        }

        const totalCap = key === 'Hit Points'
            ? Math.min(baseCap + Math.min(bonus.cap, 400) + bonus.mythicalCap, 600)
            : baseCap + bonus.cap + bonus.mythicalCap;

        const differenceToMaxItem = bonus.value - totalCap;

        const colorItem = differenceToMaxItem >= 0 ? 'green' : 'red';

        return (
            <TableRow
                key={key}
                style={rowStyle}
                onMouseEnter={(e) => e.currentTarget.style.backgroundColor = theme.palette.action.hover}
                onMouseLeave={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
            >
                <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }}>{bonus.displayName}</TableCell>
                <TableCell style={cellStyle}>{bonus.value}</TableCell>
                <TableCell style={cellStyle}><span style={{ color: colorItem }}>{differenceToMaxItem}</span></TableCell>
                <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>{totalCap}</TableCell>
                <TableCell style={cellStyle}>{baseCap}</TableCell>
                <TableCell style={cellStyle}>{Math.min(bonus.cap, key === 'Hit Points' ? 400 : bonus.cap)}</TableCell>
                <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>{bonus.mythicalCap}</TableCell>
            </TableRow>
        );
    };

    return (
        <Paper style={{ padding: '8px', flex: 1, height: '100%' }}>
            <Typography variant="h6" style={{ fontSize: '1rem', marginBottom: '8px' }}>Statistics</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }}>Attribute</TableCell>
                        <TableCell style={cellStyle}>Value</TableCell>
                        <TableCell style={cellStyle}>+/-</TableCell>
                        <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>Total Cap</TableCell>
                        <TableCell colSpan={3} style={{ ...cellStyle, textAlign: 'center', borderLeft: '1px solid', borderRight: '1px solid' }}>Caps</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ ...cellStyle, borderLeft: '1px solid' }} />
                        <TableCell style={cellStyle} />
                        <TableCell style={cellStyle} />
                        <TableCell style={{ ...cellStyle, borderRight: '1px solid' }} />
                        <TableCell style={cellStyle}>Base</TableCell>
                        <TableCell style={cellStyle}>ToA</TableCell>
                        <TableCell style={{ ...cellStyle, borderRight: '1px solid' }}>Mythical</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {statKeys.map(key => renderRow(key, true))}
                    {bonusKeys.map(renderBonusRow)}
                </TableBody>
            </Table>
        </Paper>
    );
};

export default TemplateStatisticsNew;
