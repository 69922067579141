import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { calculateStats } from "../../../utility/calculateGearStats";
import CharacterBonuses from "../old/CharacterBonuses";
import CharacterSkills from "../old/CharacterSkills";
import TemplateEquipmentViewOnly from "./TemplateEquipmentViewOnly";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from '@mui/material/styles';
import CharacterStatistics from "../old/CharacterStatistics";
import CharacterResistances from "../old/CharacterResistances";
import ItemDetails from "../shared/ItemDetails";

const TemplateContainerViewOnly = ({ template, gearBySlot, onStatisticsCalculated }) => {
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [weaponConfig, setWeaponConfig] = useState('Main Hand / Off Hand');
    const [statistics, setStatistics] = useState({});
    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        const statsResult = calculateStats(gearBySlot, weaponConfig);
        setStatistics(statsResult);
        onStatisticsCalculated(statsResult); // Pass statistics back to the parent
    }, [gearBySlot, weaponConfig, onStatisticsCalculated]);

    const handleItemClick = (itemName) => {
        if (selectedItem === itemName) {
            setSelectedItem(null);
            setSelectedSlot(null);
        } else {
            setSelectedItem(itemName);
            const slotName =
                itemName === 'L Ring' || itemName === 'R Ring'
                    ? 'Ring'
                    : itemName === 'L Wrist' || itemName === 'R Wrist'
                        ? 'Bracer'
                        : itemName;
            setSelectedSlot(slotName);
        }
    };

    const handleWeaponConfigChange = (newConfig) => {
        setWeaponConfig(newConfig);
    };

    const renderItemDetails = () => {
        const item = gearBySlot[selectedItem];
        if (!item) {
            return <Typography variant="body1">No item selected.</Typography>;
        }

        return (
            <div>
                <ItemDetails item={item} />
            </div>
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={2.3}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TemplateEquipmentViewOnly
                        gearBySlot={gearBySlot}
                        selectedSlot={selectedSlot}
                        selectedItem={selectedItem}
                        handleItemClick={handleItemClick}
                        weaponConfig={weaponConfig}
                        setWeaponConfig={handleWeaponConfigChange}
                    />
                </div>
            </Grid>
            <Grid item xs={12} sm={6} md={8} lg={4} xl={4}>
                <Grid container spacing={2} style={{ display: isLargeScreen ? 'flex' : 'block' }}>
                    {statistics && (
                        <React.Fragment>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ flex: 1 }}>
                                <CharacterStatistics statistics={statistics} selectedClass={template.class} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ flex: 1 }}>
                                <CharacterResistances statistics={statistics} selectedClass={template.class} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4} lg={4} xl={4} style={{ flex: 1 }}>
                                <CharacterSkills statistics={statistics} selectedClass={template.class} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CharacterBonuses statistics={statistics} selectedClass={template.class} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {selectedItem && (
                                    <Paper style={{ padding: '16px', marginBottom: '16px' }}>
                                        {renderItemDetails()}
                                    </Paper>
                                )}
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default TemplateContainerViewOnly;
