function calculateStats(template, weaponConfig) {
    const stats = {
        Strength: { value: 0, cap: 0, mythicalCap: 0 },
        Dexterity: { value: 0, cap: 0, mythicalCap: 0 },
        Constitution: { value: 0, cap: 0, mythicalCap: 0 },
        Quickness: { value: 0, cap: 0, mythicalCap: 0 },
        Intelligence: { value: 0, cap: 0, mythicalCap: 0 },
        Piety: { value: 0, cap: 0, mythicalCap: 0 },
        Empathy: { value: 0, cap: 0, mythicalCap: 0 },
        Charisma: { value: 0, cap: 0, mythicalCap: 0 },
        Acuity: { value: 0, cap: 0, mythicalCap: 0 }
    };

    const resistances = {
        Crush: { value: 0, mythicalCap: 0 },
        Slash: { value: 0, mythicalCap: 0 },
        Thrust: { value: 0, mythicalCap: 0 },
        Heat: { value: 0, mythicalCap: 0 },
        Cold: { value: 0, mythicalCap: 0 },
        Matter: { value: 0, mythicalCap: 0 },
        Body: { value: 0, mythicalCap: 0 },
        Spirit: { value: 0, mythicalCap: 0 },
        Energy: { value: 0, mythicalCap: 0 },
    };

    const characterBonuses  = {
        "Toa Melee Damage": { displayName: "Melee Damage", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Melee Speed": { displayName: "Melee Speed", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Style Damage": { displayName: "Style Damage", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Magic Damage": { displayName: "Magic Damage", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Cast Speed": { displayName: "Cast Speed", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Spell Duration": { displayName: "Spell Duration", value: 0, cap: 25, mythicalCap: 0 },
        "Toa Spell Range": { displayName: "Spell Range", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Heal Bonus": { displayName: "Heal Bonus", value: 0, cap: 25, mythicalCap: 0 },
        "Toa Buff Bonus": { displayName: "Buff Bonus", value: 0, cap: 25, mythicalCap: 0 },
        "Toa Debuff Bonus": { displayName: "Debuff Bonus", value: 0, cap: 25, mythicalCap: 0 },
        "Toa Fatigue": { displayName: "Fatigue", value: 0, cap: 10, mythicalCap: 0 },
        "Toa Fatigue Cap": { displayName: "Fatigue Cap", value: 0, cap: 0, mythicalCap: 0 },
        "Mythical Realm Points": { displayName: "Mythical Realm Points", value: 0, cap: 0, mythicalCap: 0 },
        "Armor Factor (AF)": { displayName: "Armor Factor (AF)", value: 0, cap: 0, mythicalCap: 0 },
        "Conversion": { displayName: "Conversion", value: 0, cap: 10, mythicalCap: 0 },
        "Radiant Aura": { displayName: "Radiant Aura", value: 0, cap: 1, mythicalCap: 0 },
        "Hit Points": { displayName: "Hit Points", value: 0, cap: 0, mythicalCap: 0 },
        "Toa Hit Points Cap": { displayName: "Hit Points Cap", value: 0, cap: 400, mythicalCap: 0 },
        "Toa Resistance Piece": { displayName: "Resistance Pierce", value: 0, cap: 10, mythicalCap: 0 },
        "Power": { displayName: "Power", value: 0, cap: 0, mythicalCap: 0 },
        "Toa Power Pool": { displayName: "Power Pool", value: 0, cap: 25, mythicalCap: 0 },
        "Toa Power Pool Cap": { displayName: "Power Pool Cap", value: 0, cap: 25, mythicalCap: 0 },
        "Arcane Siphon": { displayName: "Arcane Siphon", value: 0, cap: 25, mythicalCap: 0 },
        "Mythical Crowd Control Duration Decrease": { displayName: "CC Duration Decrease", value: 0, cap: 50, mythicalCap: 0 },
        "XP Bonus": { displayName: "XP Bonus", value: 0, cap: 25, mythicalCap: 0 },
        "Gold Bonus": { displayName: "Gold Bonus", value: 0, cap: 25, mythicalCap: 0 },
        "Realm Point Bonus": { displayName: "Realm Point Bonus", value: 0, cap: 25, mythicalCap: 0 },
        "Bounty Point Bonus": { displayName: "Bounty Point Bonus", value: 0, cap: 0, mythicalCap: 0 },
        "Concentration": { displayName: "Concentration", value: 0, cap: 25, mythicalCap: 0 },
        "Safe Fall": { displayName: "Safe Fall", value: 0, cap: 0, mythicalCap: 0 },
        "Health Regeneration": { displayName: "Health Regeneration", value: 0, cap: 25, mythicalCap: 0 },
        "Mana Regeneration": { displayName: "Power Regen", value: 0, cap: 25, mythicalCap: 0 },
        "Mythical Parry": { displayName: "Mythical Parry", value: 0, cap: 10, mythicalCap: 0 },
        "Mythical Evade": { displayName: "Mythical Evade", value: 0, cap: 10, mythicalCap: 0 },
        "Mythical Block": { displayName: "Mythical Block", value: 0, cap: 10, mythicalCap: 0 },
        "Mythical Coin": { displayName: "Mythical Coin", value: 0, cap: 0, mythicalCap: 0 },
        "Mythical DPS": { displayName: "Mythical DPS", value: 0, cap: 10, mythicalCap: 0 },
        "Mythical Spell Focus": { displayName: "Mythical Spell Focus", value: 0, cap: 25, mythicalCap: 0 },
        "Mythical Endurance Regen": { displayName: "Mythical Endurance Regen", value: 0, cap: 50, mythicalCap: 0 },
        "Mythical Health Regen": { displayName: "Mythical Health Regen", value: 0, cap: 50, mythicalCap: 0 },
        "Mythical Power Regen": { displayName: "Mythical Power Regen", value: 0, cap: 50, mythicalCap: 0 },
        "Mythical Physical Defense": { displayName: "Mythical Physical Defense", value: 0, cap: 25, mythicalCap: 0 },
        "All Focus": { displayName: "Focus", value: 0, cap: 50, mythicalCap: 0 },
    };

    const maxToaOvercap = 26;
    const maxCombinedCapIncrease = 52;
    const weaponSlots = ['Main Hand', 'Off Hand', 'Two-Hand', 'Ranged'];

    let toaHitPointsCapBonus = 0;
    const skills = {};
    const abilities = {};

    for (const slot in template) {
        if (weaponSlots.includes(slot) && !weaponConfig.includes(slot)) {
            continue; // Skip this slot if it's not included in the weaponConfig
        }
        const item = template[slot];
        if (item && item.abilities) {
            item.abilities.forEach(ability => {
                // Assuming each ability has a position, magic_type, and spell
                const abilityKey = `${ability.position}-${ability.magic_type}-${ability.spell}`;
                if (!abilities[abilityKey]) {
                    abilities[abilityKey] = { position: ability.position, magic_type: ability.magic_type, spell: ability.spell };
                }
            });
        }
        if (item && item.bonuses) {
            item.bonuses.forEach(bonus => {
                if (bonus.type === "Stats" && stats.hasOwnProperty(bonus.id)) {
                    stats[bonus.id].value += bonus.value;
                }
                if ((bonus.type === "Toa Overcap" || bonus.type === "Mythical Cap Increase") && stats.hasOwnProperty(bonus.id)) {
                    const capIncrease = bonus.value;
                    if (bonus.type === "Toa Overcap") {
                        stats[bonus.id].cap = Math.min(stats[bonus.id].cap + capIncrease, maxToaOvercap);
                    } else {
                        stats[bonus.id].mythicalCap = Math.min(stats[bonus.id].mythicalCap + capIncrease, maxCombinedCapIncrease);
                    }
                }
                // Handling resistances
                if (bonus.type === "Resistance" && resistances.hasOwnProperty(bonus.id)) {
                    resistances[bonus.id].value += bonus.value;
                }

                // Handling Mythical Resistance Cap
                if (bonus.type === "Mythical Resistance Cap" && resistances.hasOwnProperty(bonus.id)) {
                    resistances[bonus.id].mythicalCap += bonus.value;
                }

                if (bonus.type === "Mythical Resist and Cap" && resistances.hasOwnProperty(bonus.id)) {
                    resistances[bonus.id].value += bonus.value; // Add to the resistance value
                    resistances[bonus.id].mythicalCap += bonus.value; // Also add to the mythicalCap
                }

                // Handling character bonuses
                if (bonus.type !== "Stats" && bonus.type !== "Toa Overcap" && bonus.type !== "Mythical Cap Increase" && bonus.type !== "Mythical Resist and Cap" && bonus.type !== "Resistance" && bonus.type !== "Mythical Resistance Cap" && bonus.type !== "Skills") {
                    let bonusKey = bonus.id || bonus.type;

                    if (!characterBonuses[bonusKey]) {
                        characterBonuses[bonusKey] = { displayName: bonus.id || bonus.type, value: 0, cap: 0, mythicalCap: 0 };
                    }

                    characterBonuses[bonusKey].value += bonus.value;
                    // Additional logic for cap and mythicalCap if applicable
                }
                if (bonus.type === "Toa Hit Points Cap") {
                    toaHitPointsCapBonus += bonus.value;
                }
                if (bonus.type === "Skills") {
                    const skillName = bonus.id;
                    if (!skills[skillName]) {
                        skills[skillName] = { value: 0, cap: 11 }; // Initialize the skill if it doesn't exist
                    }
                    skills[skillName].value += bonus.value;
                }

                if (bonus.type === "Mythical Physical Defense") {
                    ["Crush", "Slash", "Thrust"].forEach(resistanceType => {
                        resistances[resistanceType].value += bonus.value;
                        resistances[resistanceType].mythicalCap += bonus.value;
                    });
                }
                // console.log(`Processing bonus: ${bonus.type}, ID: ${bonus.id}, Value: ${bonus.value}`);
            });
        }
    }

    if (characterBonuses["Hit Points"]) {
        characterBonuses["Hit Points"].cap = Math.min(characterBonuses["Hit Points"].cap + toaHitPointsCapBonus, 600);
    }

    // Special handling for Acuity affecting other stats
    if (stats.Acuity) {
        ["Intelligence", "Piety", "Empathy", "Charisma"].forEach(stat => {
            stats[stat].value += stats.Acuity.value;
            stats[stat].cap += stats.Acuity.cap;
            stats[stat].mythicalCap += stats.Acuity.mythicalCap;
        });
    }

    const combinedStats = { ...stats, ...resistances, ...characterBonuses, skills, abilities };

    // Adjusting the final values and caps for stats and resistances
    for (const stat in stats) {
        // Removed the Math.min logic
        combinedStats[stat].value = stats[stat].value; // Assign the actual total value
    }
    // console.log("Final Stats:", combinedStats)
    return combinedStats;
}

export { calculateStats };
