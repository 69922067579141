import React from 'react';
import {
    Paper,
    List,
    ListItemButton,
    ListItemText,
    RadioGroup,
    FormControlLabel,
    Radio,
    Grid, Chip
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

function TemplateEquipment({ gearBySlot, handleItemClick, selectedItem, weaponConfig, setWeaponConfig }) {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;
    const gearSlots = ['Torso', 'Helm', 'Arms', 'Hands', 'Legs', 'Feet', 'Necklace', 'Cloak', 'Jewel', 'Belt', 'L Ring', 'R Ring', 'L Wrist', 'R Wrist', 'Mythirian', 'Main Hand', 'Off Hand', 'Two-Hand', 'Ranged'];

    const selectedStyle = {
        boxShadow: `inset 0 0 0 4px ${primaryColor}`,
        backgroundColor: 'transparent'
    };

    return (
        <Paper style={{ padding: '8px', flex: 1 }}>
            <RadioGroup row value={weaponConfig} onChange={(event) => setWeaponConfig(event.target.value)} style={{ marginBottom: '8px' }}>
                <FormControlLabel value="Main Hand / Off Hand" control={<Radio size="small" />} label="MH / OH" />
                <FormControlLabel value="Two-Hand" control={<Radio size="small" />} label="Two-Hand" />
                <FormControlLabel value="Ranged" control={<Radio size="small" />} label="Ranged" />
                <FormControlLabel value="Weaponless" control={<Radio size="small" />} label="Weaponless" />
            </RadioGroup>
            <List component="nav" dense>
                {gearSlots.map(slot => (
                    <ListItemButton
                        key={slot}
                        selected={selectedItem === slot}
                        style={{
                            ...selectedItem === slot ? selectedStyle : {},
                            padding: '4px 8px'
                        }}
                        onClick={() => handleItemClick(slot)}
                    >
                        <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
                            <Grid item xs>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                            <Chip
                                                label={slot}
                                                variant="outlined"
                                                size="small"
                                                style={{ marginRight: '8px', width: '80px' }}
                                            />
                                            <span style={{ color: primaryColor }}>{gearBySlot[slot]?.name || ''}</span>
                                        </React.Fragment>
                                    }
                                    primaryTypographyProps={{ style: { fontSize: '0.9rem' } }}
                                />
                            </Grid>
                        </Grid>
                    </ListItemButton>
                ))}
            </List>
        </Paper>
    );
}

export default TemplateEquipment;
