import React from 'react';
import { Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const classRestrictedSkills = {
    'Armsman': {
        'Casting': [],
        'Melee': ['Polearm', 'Two Handed', 'Slash', 'Crush', 'Thrust'],
        'Duel': [],
        'Other': ['Crossbow', 'Parry', 'Shield']
    },
    'Cabalist': {
        'Casting': ['Body Magic', 'Matter Magic', 'Spirit Magic'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Cleric': {
        'Casting': ['Rejuvenation', 'Enhancements', 'Smiting'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Friar': {
        'Casting': ['Rejuvenation', 'Enhancements'],
        'Melee': ['Staff'],
        'Duel': [],
        'Other': ['Parry']
    },
    'Heretic': {
        'Casting': ['Rejuvenation', 'Enhancements'],
        'Melee': ['Flexible', 'Crush'],
        'Duel': [],
        'Other': ['Shield']
    },
    'Infiltrator': {
        'Casting': [],
        'Melee': ['Slash', 'Thrust'],
        'Duel': ['Dual Wield'],
        'Other': ['Critical Strike', 'Stealth', 'Envenom']
    },
    'Mauler': {
        'Casting': ['Power Strikes', 'Magnetism', 'Aura Manipulation'],
        'Melee': ['Fist Wraps', 'Mauler Staff'],
        'Duel': [],
        'Other': []
    },
    'Mercenary': {
        'Casting': [],
        'Melee': ['Slash', 'Crush', 'Thrust'],
        'Duel': ['Dual Wield'],
        'Other': ['Shield', 'Parry']
    },
    'Minstrel': {
        'Casting': ['Instruments'],
        'Melee': ['Slash', 'Thrust'],
        'Duel': [],
        'Other': ['Stealth']
    },
    'Necromancer': {
        'Casting': ['Deathsight', 'Death Servant', 'Painworking'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Paladin': {
        'Casting': ['Chants'],
        'Melee': ['Two Handed', 'Slash', 'Crush', 'Thrust'],
        'Duel': [],
        'Other': ['Shield', 'Parry']
    },
    'Reaver': {
        'Casting': ['Soulrending'],
        'Melee': ['Slash', 'Crush', 'Thrust', 'Flexible'],
        'Duel': [],
        'Other': ['Shield', 'Parry']
    },
    'Scout': {
        'Casting': [],
        'Melee': ['Slash', 'Thrust'],
        'Duel': [],
        'Archery': ['Archery'],
        'Other': ['Stealth', 'Shield']
    },
    'Sorcerer': {
        'Casting': ['Body Magic', 'Matter Magic', 'Mind Magic'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Theurgist': {
        'Casting': ['Earth Magic', 'Cold Magic', 'Wind Magic'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Wizard': {
        'Casting': ['Earth Magic', 'Cold Magic', 'Fire Magic'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Berserker': {
        'Casting': [],
        'Melee': ['Sword', 'Axe', 'Hammer'],
        'Duel': ['Left Axe'],
        'Other': ['Parry']
    },
    'Bonedancer': {
        'Casting': ['Darkness', 'Suppression', 'Bone Army'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Healer': {
        'Casting': ['Mending', 'Augmentation', 'Pacification'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Hunter': {
        'Casting': ['Beastcraft'],
        'Melee': ['Sword', 'Spear'],
        'Duel': [],
        'Archery': ['Archery'],
        'Other': ['Stealth']
    },
    'Runemaster': {
        'Casting': ['Darkness', 'Suppression', 'Runecarving'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Savage': {
        'Casting': [],
        'Melee': ['Sword', 'Axe', 'Hammer', 'Hand to Hand'],
        'Duel': [],
        'Other': ['Parry', 'Savagery']
    },
    'Shadowblade': {
        'Casting': [],
        'Melee': ['Sword', 'Axe'],
        'Duel': ['Left Axe'],
        'Other': ['Stealth', 'Critical Strike', 'Envenom']
    },
    'Shaman': {
        'Casting': ['Mending', 'Augmentation', 'Cave Magic'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Skald': {
        'Casting': ['Battlesongs'],
        'Melee': ['Sword', 'Axe', 'Hammer'],
        'Duel': [],
        'Other': ['Parry']
    },
    'Spiritmaster': {
        'Casting': ['Darkness', 'Suppression', 'Summoning'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Thane': {
        'Casting': ['Stormcalling'],
        'Melee': ['Sword', 'Axe', 'Hammer'],
        'Duel': [],
        'Other': ['Parry', 'Shield']
    },
    'Valkyrie': {
        'Casting': ["Odin's Will", 'Mending'],
        'Melee': ['Sword', 'Spear'],
        'Duel': [],
        'Other': ['Parry', 'Shield']
    },
    'Warlock': {
        'Casting': ['Witchcraft', 'Cursing', 'Hexing'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Warrior': {
        'Casting': [],
        'Melee': ['Sword', 'Axe', 'Hammer'],
        'Duel': [],
        'Other': ['Parry', 'Shield', 'Thrown']
    },
    'Animist': {
        'Casting': ['Creeping Path', 'Verdant Path', 'Arboreal Path'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Bainshee': {
        'Casting': ['Phantasmal Wail', 'Ethereal Shriek', 'Spectral Guard'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Bard': {
        'Casting': ['Nurture', 'Regrowth', 'Music'],
        'Melee': ['Blades', 'Blunt'],
        'Duel': [],
        'Other': []
    },
    'Blademaster': {
        'Casting': [],
        'Melee': ['Blades', 'Blunt', 'Piercing'],
        'Duel': ['Celtic Dual'],
        'Other': ['Parry', 'Shield']
    },
    'Champion': {
        'Casting': ['Valor'],
        'Melee': ['Blades', 'Blunt', 'Piercing', 'Large Weaponry'],
        'Duel': [],
        'Other': ['Parry', 'Shield']
    },
    'Druid': {
        'Casting': ['Nurture', 'Regrowth', 'Nature Affinity'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Eldritch': {
        'Casting': ['Mana Magic', 'Light Magic', 'Void Magic'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Enchanter': {
        'Casting': ['Mana Magic', 'Light Magic', 'Enchantments'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Hero': {
        'Casting': [],
        'Melee': ['Blades', 'Blunt', 'Piercing', 'Celtic Spear', 'Large Weaponry'],
        'Duel': [],
        'Other': ['Parry', 'Shield']
    },
    'Mentalist': {
        'Casting': ['Mana Magic', 'Light Magic', 'Mentalism'],
        'Melee': [],
        'Duel': [],
        'Other': []
    },
    'Nightshade': {
        'Casting': [],
        'Melee': ['Piercing', 'Blades'],
        'Duel': ['Celtic Dual'],
        'Other': ['Stealth', 'Envenom', 'Critical Strike']
    },
    'Ranger': {
        'Casting': [],
        'Melee': ['Piercing', 'Blades'],
        'Duel': ['Celtic Dual'],
        'Archery': ['Archery'],
        'Other': ['Stealth']
    },
    'Valewalker': {
        'Casting': ['Arboreal Path'],
        'Melee': ['Scythe'],
        'Duel': [],
        'Other': ['Parry']
    },
    'Vampiir': {
        'Casting': ['Shadow Mastery', 'Vampiiric Embrace', 'Dementia'],
        'Melee': ['Piercing'],
        'Duel': [],
        'Other': []
    },
    'Warden': {
        'Casting': ['Nurture', 'Regrowth'],
        'Melee': ['Blades', 'Blunt'],
        'Duel': [],
        'Other': ['Parry', 'Shield']
    },
};

function CharacterSkills({ statistics, selectedClass }) {
    const theme = useTheme();
    const skills = statistics.skills || {};
    const relevantSkills = classRestrictedSkills[selectedClass] || {};

    const allCastingBonus = skills['All Casting']?.value || 0;
    const allMeleeBonus = skills['All Melee']?.value || 0;
    const allDuelBonus = skills['All Duel']?.value || 0;
    const allArcheryBonus = skills['All Archery']?.value || 0;

    const getColorForSkillValue = (value, maxCap) => {
        const percentage = (value / maxCap) * 100;

        if (percentage >= 100) {
            return theme.palette.primary.main; // Primary color
        } else if (percentage >= 90) {
            return 'green';
        } else if (percentage >= 80) {
            return 'orange';
        } else {
            return 'red';
        }
    };

    const renderSkill = (skill) => {
        let skillValue = skills[skill]?.value || 0;

        if (relevantSkills['Casting']?.includes(skill)) {
            skillValue += allCastingBonus;
        } else if (relevantSkills['Melee']?.includes(skill)) {
            skillValue += allMeleeBonus;
        } else if (relevantSkills['Duel']?.includes(skill)) {
            skillValue += allDuelBonus;
        } else if (relevantSkills['Archery']?.includes(skill)) {
            skillValue += allArcheryBonus;
        }

        // No longer capping the skill value at 11
        const color = getColorForSkillValue(skillValue, 11); // Assuming you want to keep the color coding logic the same

        return (
            <div key={skill} style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">{skill}:</Typography>
                <Typography variant="body2" style={{ color }}>{skillValue}/11</Typography>
            </div>
        );
    };


    return (
        <Paper style={{ padding: '16px', flex: 1, height: '100%' }}>
            <Typography variant="body1">Skills</Typography>
            <hr/>
            {Object.values(relevantSkills).flat().map(renderSkill)}
        </Paper>
    );
}

export default CharacterSkills;