import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, FormControl, InputLabel, Grid, TableFooter } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const OverView = ({ characters }) => {
    const [characterDetails, setCharacterDetails] = useState({});
    const [filteredCharacters, setFilteredCharacters] = useState([]);
    const [selectedRealm, setSelectedRealm] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedServer, setSelectedServer] = useState('');

    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const realmMapping = {
        1: 'Albion',
        2: 'Midgard',
        3: 'Hibernia'
    };

    const fetchCharacterData = async (characterId) => {
        try {
            const url = `https://api.camelotherald.com/character/info/${characterId}`;
            const response = await fetch(url);
            const data = await response.json();
            // console.log('API Response:', data); // Log the API response
            setCharacterDetails(prev => ({ ...prev, [characterId]: data }));
        } catch (error) {
            console.error('Error fetching character data:', error);
        }
    };

    useEffect(() => {
        characters.forEach(character => {
            fetchCharacterData(character.characterId);
        });
    }, [characters]);

    useEffect(() => {
        const filtered = characters.filter(character => {
            const details = characterDetails[character.characterId] || {};
            const realmName = realmMapping[details.realm];
            return (
                (!selectedRealm || realmName === selectedRealm) &&
                (!selectedClass || details.class_name === selectedClass) &&
                (!selectedServer || character.server === selectedServer)
            );
        });
        setFilteredCharacters(filtered);
    }, [characters, characterDetails, selectedRealm, selectedClass, selectedServer]);

    const calculateTotals = (characters) => {
        return characters.reduce((totals, character) => {
            const details = characterDetails[character.characterId] || {};
            const realmWarStats = details.realm_war_stats?.current || {};
            const playerKills = realmWarStats.player_kills || {};

            totals.totalKills += playerKills.total?.kills || 0;
            totals.totalDeaths += playerKills.total?.deaths || 0;
            totals.totalDeathBlows += playerKills.total?.death_blows || 0;
            totals.totalSoloKills += playerKills.total?.solo_kills || 0;

            totals.albionKills += playerKills.albion?.kills || 0;
            totals.albionDeathBlows += playerKills.albion?.death_blows || 0;
            totals.albionSoloKills += playerKills.albion?.solo_kills || 0;

            totals.midgardKills += playerKills.midgard?.kills || 0;
            totals.midgardDeathBlows += playerKills.midgard?.death_blows || 0;
            totals.midgardSoloKills += playerKills.midgard?.solo_kills || 0;

            totals.hiberniaKills += playerKills.hibernia?.kills || 0;
            totals.hiberniaDeathBlows += playerKills.hibernia?.death_blows || 0;
            totals.hiberniaSoloKills += playerKills.hibernia?.solo_kills || 0;

            totals.realmPoints += realmWarStats.realm_points || 0;

            return totals;
        }, {
            totalKills: 0,
            totalDeaths: 0,
            totalDeathBlows: 0,
            totalSoloKills: 0,
            albionKills: 0,
            albionDeathBlows: 0,
            albionSoloKills: 0,
            midgardKills: 0,
            midgardDeathBlows: 0,
            midgardSoloKills: 0,
            hiberniaKills: 0,
            hiberniaDeathBlows: 0,
            hiberniaSoloKills: 0,
            realmPoints: 0
        });
    };

    const totals = calculateTotals(filteredCharacters);

    const cellStyle = { color: primaryColor, borderRight: `1px solid ${primaryColor}` };
    const boldFooterStyle = { fontWeight: 'bold', fontSize: '1rem', color: primaryColor, borderRight: `1px solid ${primaryColor}` };

    return (
        <div>
            <Grid container spacing={2} marginBottom={2}>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Realm</InputLabel>
                        <Select value={selectedRealm} onChange={(e) => setSelectedRealm(e.target.value)}>
                            <MenuItem value=''>All Realms</MenuItem>
                            <MenuItem value='Albion'>Albion</MenuItem>
                            <MenuItem value='Midgard'>Midgard</MenuItem>
                            <MenuItem value='Hibernia'>Hibernia</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Class</InputLabel>
                        <Select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                            <MenuItem value=''>All Classes</MenuItem>
                            {Array.from(new Set(characters.map(character => characterDetails[character.characterId]?.class_name)))
                                .filter(Boolean)
                                .map(cls => (
                                    <MenuItem key={cls} value={cls}>{cls}</MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Server</InputLabel>
                        <Select value={selectedServer} onChange={(e) => setSelectedServer(e.target.value)}>
                            <MenuItem value=''>All Servers</MenuItem>
                            <MenuItem value='Ywain'>Ywain</MenuItem>
                            <MenuItem value='Gaheris'>Gaheris</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: primaryColor }} rowSpan={2}>Name</TableCell>
                            <TableCell style={{ color: primaryColor }} rowSpan={2}>Class</TableCell>
                            <TableCell style={{ color: primaryColor }} rowSpan={2}>Realm</TableCell>
                            <TableCell style={{ color: primaryColor }} rowSpan={2}>Server</TableCell>
                            <TableCell style={{ color: primaryColor }} rowSpan={2}>Race</TableCell>
                            <TableCell style={{ color: primaryColor }} rowSpan={2}>Realm Points</TableCell>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}`, borderLeft: `1px solid ${primaryColor}` }} colSpan={4}>Total</TableCell>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }} colSpan={3}>Albion</TableCell>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }} colSpan={3}>Midgard</TableCell>
                            <TableCell style={{ color: primaryColor }} colSpan={3}>Hibernia</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}`, borderLeft: `1px solid ${primaryColor}`}}>Kills</TableCell>
                            <TableCell style={cellStyle}>Deaths</TableCell>
                            <TableCell style={cellStyle}>Death Blows</TableCell>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }}>Solo Kills</TableCell>
                            <TableCell style={cellStyle}>Kills</TableCell>
                            <TableCell style={cellStyle}>Death Blows</TableCell>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }}>Solo Kills</TableCell>
                            <TableCell style={cellStyle}>Kills</TableCell>
                            <TableCell style={cellStyle}>Death Blows</TableCell>
                            <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }}>Solo Kills</TableCell>
                            <TableCell style={cellStyle}>Kills</TableCell>
                            <TableCell style={cellStyle}>Death Blows</TableCell>
                            <TableCell style={{ color: primaryColor }}>Solo Kills</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredCharacters.map(character => {
                            const details = characterDetails[character.characterId] || {};
                            const realmWarStats = details.realm_war_stats?.current || {};
                            const playerKills = realmWarStats.player_kills || {};

                            return (
                                <TableRow key={character.id}>
                                    <TableCell style={{ color: primaryColor }}>{character.name}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{details.class_name || character.class}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{realmMapping[details.realm]}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{character.server}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{details.race}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{realmWarStats.realm_points || 0}</TableCell>
                                    <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}`, borderLeft: `1px solid ${primaryColor}` }}>{playerKills.total?.kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.total?.deaths || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.total?.death_blows || 0}</TableCell>
                                    <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }}>{playerKills.total?.solo_kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.albion?.kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.albion?.death_blows || 0}</TableCell>
                                    <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }}>{playerKills.albion?.solo_kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.midgard?.kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.midgard?.death_blows || 0}</TableCell>
                                    <TableCell style={{ color: primaryColor, borderRight: `1px solid ${primaryColor}` }}>{playerKills.midgard?.solo_kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.hibernia?.kills || 0}</TableCell>
                                    <TableCell style={cellStyle}>{playerKills.hibernia?.death_blows || 0}</TableCell>
                                    <TableCell style={{ color: primaryColor }}>{playerKills.hibernia?.solo_kills || 0}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={5} style={boldFooterStyle}>Total</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.realmPoints}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.totalKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.totalDeaths}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.totalDeathBlows}</TableCell>
                            <TableCell style={{ ...boldFooterStyle, borderRight: `1px solid ${primaryColor}` }}>{totals.totalSoloKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.albionKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.albionDeathBlows}</TableCell>
                            <TableCell style={{ ...boldFooterStyle, borderRight: `1px solid ${primaryColor}` }}>{totals.albionSoloKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.midgardKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.midgardDeathBlows}</TableCell>
                            <TableCell style={{ ...boldFooterStyle, borderRight: `1px solid ${primaryColor}` }}>{totals.midgardSoloKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.hiberniaKills}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.hiberniaDeathBlows}</TableCell>
                            <TableCell style={boldFooterStyle}>{totals.hiberniaSoloKills}</TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
};

export default OverView;
