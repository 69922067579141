import { auth } from '../firebase/config';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuthContext } from './useAuthContext';
import axios from 'axios';

const useLogin = () => {
    const { dispatch } = useAuthContext();

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (user) {
                const token = await user.getIdToken();
                localStorage.setItem('authToken', token);

                // Check if user exists in your database
                try {
                    const userResponse = await axios.get(`http://localhost:8080/users/${user.uid}`, {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    if (!userResponse.data) {
                        // If user doesn't exist, create it
                        const userPayload = { uid: user.uid, displayName: user.displayName };
                        await axios.post('http://localhost:8080/users/create', userPayload, {
                            headers: { Authorization: `Bearer ${token}` }
                        });
                    }
                } catch (err) {
                    if (err.response && err.response.status === 404) {
                        // If user doesn't exist, create it
                        const userPayload = { uid: user.uid, displayName: user.displayName };
                        try {
                            await axios.post('http://localhost:8080/users/create', userPayload, {
                                headers: { Authorization: `Bearer ${token}` }
                            });
                        } catch (postError) {
                            console.error('Error creating user in database:', postError.response ? postError.response.data : postError.message);
                        }
                    } else {
                        console.error('Error checking user in database:', err.response ? err.response.data : err.message);
                    }
                }

                dispatch({ type: 'LOGIN', payload: { user, token } });
            } else {
                console.error('No user object found');
            }
        } catch (error) {
            console.error('Login error:', error.message);
            throw error;
        }
    };

    return { login };
};

export default useLogin;
