import React, { useState, useEffect } from 'react';
import { Button, Grid, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import PublicTemplates from '../../components/templates/PublicTemplates';

const realmToClasses = {
    Albion: [
        'Armsman', 'Cabalist', 'Cleric', 'Friar', 'Heretic', 'Infiltrator', 'Mauler', 'Mercenary',
        'Minstrel', 'Necromancer', 'Paladin', 'Reaver', 'Scout', 'Sorcerer', 'Theurgist', 'Wizard'
    ],
    Midgard: [
        'Berserker', 'Bonedancer', 'Healer', 'Hunter', 'Mauler', 'Runemaster', 'Savage', 'Shadowblade',
        'Shaman', 'Skald', 'Spiritmaster', 'Thane', 'Valkyrie', 'Warlock', 'Warrior'
    ],
    Hibernia: [
        'Animist', 'Bainshee', 'Bard', 'Blademaster', 'Champion', 'Druid', 'Eldritch', 'Enchanter', 'Hero',
        'Mauler', 'Mentalist', 'Nightshade', 'Ranger', 'Valewalker', 'Vampiir', 'Warden'
    ],
};

const Templates = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const [selectedRealm, setSelectedRealm] = useState(queryParams.get('realm') || null);
    const [selectedClass, setSelectedClass] = useState(queryParams.get('class') || null);

    useEffect(() => {
        // Update the URL when realm or class changes
        const params = new URLSearchParams();
        if (selectedRealm) {
            params.set('realm', selectedRealm);
        }
        if (selectedClass) {
            params.set('class', selectedClass);
        }
        navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    }, [selectedRealm, selectedClass, navigate, location.pathname]);

    const handleRealmClick = (realm) => {
        setSelectedRealm(realm);
        setSelectedClass(null); // Reset class selection when changing realm
    };

    const handleClassClick = (className) => {
        setSelectedClass(className);
    };

    return (
        <Box p={2}>
            <Grid container spacing={2} justifyContent="center">
                {Object.keys(realmToClasses).map(realm => (
                    <Grid item key={realm}>
                        <Button
                            variant={selectedRealm === realm ? "contained" : "outlined"}
                            color="primary"
                            onClick={() => handleRealmClick(realm)}
                        >
                            {realm}
                        </Button>
                    </Grid>
                ))}
            </Grid>

            {selectedRealm && (
                <React.Fragment>
                    <Grid container mt={2} spacing={2} justifyContent="center">
                        {realmToClasses[selectedRealm].map(className => (
                            <Grid item key={className}>
                                <Button
                                    variant={selectedClass === className ? "contained" : "outlined"}
                                    color="secondary"
                                    onClick={() => handleClassClick(className)}
                                >
                                    {className}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </React.Fragment>
            )}

            {selectedRealm && selectedClass && (
                <Box mt={4}>
                    <PublicTemplates realm={selectedRealm} class={selectedClass} />
                </Box>
            )}
        </Box>
    );
};

export default Templates;
