import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const slotOrder = [
    'Torso', 'Helm', 'Arms', 'Hands', 'Legs', 'Feet', 'Necklace', 'Cloak', 'Jewel', 'Belt',
    'L Ring', 'R Ring', 'L Wrist', 'R Wrist', 'Mythirian', 'Main Hand', 'Off Hand', 'Two-Hand', 'Ranged'
];

const classRestrictedBonuses = {
    'Armsman': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)', 'Resistance Pierce'],
    'Cabalist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Cleric': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Friar': ['Mythical Physical Defense', 'Magic Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Heretic': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Infiltrator': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)', 'Mythical Spell Focus', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Power Regen', 'Mythical Power Regen', 'Radiant Aura'],
    'Mauler': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Mercenary': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Minstrel': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Necromancer': ['Mythical Physical Defense', 'Radiant Aura', 'Buff Bonus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Paladin': ['Mythical Physical Defense', 'Radiant Aura', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Reaver': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Scout': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Sorcerer': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Theurgist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Wizard': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Berserker': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Bonedancer': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Healer': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Hunter': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Runemaster': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Savage': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Shadowblade': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Shaman': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Skald': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Spiritmaster': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Thane': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Valkyrie': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Warlock': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Warrior': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Animist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Bainshee': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Bard': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Blademaster': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Champion': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Druid': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Eldritch': ['Mythical Physical Defense', 'Radiant Aura', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Enchanter': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Hero': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Spell Duration', 'Buff Bonus', 'Radiant Aura', 'Power Pool', 'Power Pool Cap', 'Arcane Siphon', 'Mana Regeneration', 'Mythical Spell Focus', 'Mythical Power Regen', 'Focus', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Mentalist': ['Mythical Physical Defense', 'Melee Damage', 'Melee Speed', 'Mythical Block', 'Mythical DPS', 'Mythical Evade', 'Mythical Parry', 'Style Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Nightshade': ['Mythical Physical Defense', 'Magic Damage', 'Cast Speed', 'Spell Range', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Ranger': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Valewalker': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Vampiir': ['Mythical Physical Defense', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)'],
    'Warden': ['Mythical Physical Defense', 'Magic Damage', 'Hit Points Cap', 'Hit Points', 'Armor Factor (AF)']
};

function TemplateExportButton({ template, statistics, id }) {
    const restrictedBonuses = classRestrictedBonuses[template.class] || [];

    const handleExportClick = () => {
        // Function to format the statistics
        const formatStatistics = (statKeys, statCategoryName, isResistance = false) => {
            return `
${statCategoryName}:
${statKeys.map(key => {
                const stat = statistics[key];
                if (!stat) return null;

                if (isResistance) {
                    const baseCap = 26; // Base cap for resistances
                    const totalCap = stat.mythicalCap + baseCap;
                    return `${key}: ${stat.value} / ${totalCap}`;
                } else {
                    const baseCap = 75; // Base cap for other statistics
                    const totalCap = stat.cap + stat.mythicalCap + baseCap;
                    return `${key}: ${stat.value} / ${totalCap}`;
                }
            }).filter(Boolean).join('\n')}
`;
        };

        // Define the keys for each category
        const statKeys = ['Strength', 'Constitution', 'Dexterity', 'Quickness', 'Intelligence', 'Piety', 'Empathy', 'Charisma', 'Acuity'];
        const resistanceKeys = ['Crush', 'Slash', 'Thrust', 'Heat', 'Cold', 'Matter', 'Body', 'Spirit', 'Energy'];
        const bonusKeys = Object.keys(statistics).filter(key => statistics[key].displayName && !restrictedBonuses.includes(statistics[key].displayName));
        const skillKeys = Object.keys(statistics.skills || {});

        // Sort items according to the defined order in slotOrder
        const sortedItems = Object.entries(template.items).sort(([slotA], [slotB]) => {
            const indexA = slotOrder.indexOf(slotA);
            const indexB = slotOrder.indexOf(slotB);
            return indexA - indexB;
        });

        // Prepare data to export as a text file
        const exportData = `Template created on www.daocbuddy.com by ${template.ownerName}
Template Link: https://www.daocbuddy.com/templates/${id}
Template: ${template.name || 'Unnamed Template'}
Created By: ${template.ownerName || 'Unknown'}
Realm: ${template.realm || 'Unknown'}
Class: ${template.class || 'Unknown'}
Level: ${template.level || 'Unknown'}

${formatStatistics(statKeys, 'Statistics')}
${formatStatistics(resistanceKeys, 'Resistances', true)}

Skills:
${skillKeys.map(skill => {
            const skillData = statistics.skills[skill];
            return `${skill}: ${skillData.value} / 11`;
        }).join('\n')}

Bonuses:
${bonusKeys.map(key => {
            const bonus = statistics[key];
            if (bonus.value === 0) return null; // Skip bonuses with value 0

            let maxCap;
            if (bonus.displayName === "Armor Factor (AF)") {
                maxCap = 50; // Special case for Armor Factor (AF)
            } else {
                maxCap = bonus.cap + bonus.mythicalCap;
            }

            return `${bonus.displayName}: ${bonus.value} / ${maxCap}`;
        }).filter(Boolean).join('\n')} 

Items:
${sortedItems.map(([slot, item]) => {
            const bonuses = item.bonuses.map(bonus => {
                if (bonus.value === 0) return null; // Skip bonuses with value 0
                return `- ${bonus.type} (${bonus.id}): ${bonus.value}`;
            }).filter(Boolean).join('\n');

            const abilities = item.abilities ? item.abilities.map(ability => `- ${ability.magic_type} (${ability.position}): ${ability.spell}`).join('\n') : 'None';

            return `-----
[${slot}]
${item.name || 'Unknown'}
Category: ${item.category || 'Unknown'}
Utility: ${item.utility || 'Unknown'}
Bonuses:
${bonuses || 'None'}
Abilities:
${abilities}

`; // Add an extra newline at the end of each item's details
        }).join('\n')}
`;

        // Create a blob from the text data
        const blob = new Blob([exportData], { type: 'text/plain' });
        const url = window.URL.createObjectURL(blob);

        // Create a link element to download the file
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${template.name || 'template'}.txt`;

        // Append the link to the body, click it, and remove it
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    };

    return (
            <IconButton color="primary" onClick={handleExportClick}>
                <DownloadIcon />
            </IconButton>
    )
}

export default TemplateExportButton;
